@use '../styles/colors.scss';

@mixin common {
  all: unset;

  padding: 0 1.9rem;
  font-weight: 700;
  font-size: 1.333rem;
  line-height: 3rem;
  border-radius: 1.5rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  border: 0;
  outline: 0;
  text-decoration: none;

  // lastenAreena has overrides
  background: colors.$blackOpacity70;
  color: colors.$gray05;

  &:focus,
  :global(.hover-styles-enabled) &:hover {
    // lastenAreena has overrides
    background: colors.$gray05;
    color: colors.$gray95;
    -webkit-text-fill-color: colors.$gray95;
  }

  & svg,
  .buttonIcon {
    margin: auto 0.7rem auto 0;
    height: 1.7rem;
    width: 1.7rem;
    position: relative;
    top: 1px;

    & path {
      fill: currentColor;
    }
  }

  :global(.lasten) & {
    color: colors.$white;
  }

  :global(.lasten) &:focus,
  :global(.lasten):global(.hover-styles-enabled) &:hover {
    background: colors.$yleDarkYellow;
    color: colors.$white;
    -webkit-text-fill-color: colors.$white;
  }
}
