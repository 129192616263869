@use '../styles/colors';

$hiddenTransform: translateY(calc(100% + 4px));

@keyframes slideIn {
  from {
    transform: $hiddenTransform;
    visibility: hidden;
  }
}

.notifications {
  box-sizing: border-box;
  position: fixed;
  bottom: 2rem;
  max-width: 100%;
  right: 2rem;
  width: 30rem;
}

.itemWrapper {
  margin-top: 1rem;
  transition: margin-top 0.5s 0.5s;

  &.hidden {
    margin-top: 0;
  }
}

.notificationItem {
  box-sizing: border-box;
  background-color: colors.$gray05;
  color: colors.$gray95;
  padding: 1rem 1.4rem;
  border-radius: 1.2rem;
  font-family: inherit;
  font-weight: 400;
  font-size: 1.5rem;
  text-transform: uppercase;
  animation: slideIn 0.5s;
  cursor: pointer;
  user-select: none;

  &.hidden {
    transform: $hiddenTransform;
    visibility: hidden;
  }
  transition:
    transform 0.5s,
    visibility 0.5s;
}

.notificationItemOuter {
  overflow: hidden;
  border-radius: 1.2rem;
  transition: max-height 0.5s 0.5s;
  max-height: 100%;
}
