@use '../styles/colors';
.player {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fixedPlayerWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: colors.$black;
}

@mixin scrollTool {
  position: absolute;
  left: 45%;
  width: 10%;
  height: 5.75rem;
}

.scrollToolUp {
  @include scrollTool;
  top: 0;
  z-index: 99;
}

.scrollToolDown {
  @include scrollTool;
  bottom: 0;
}

.scrollerContainer {
  margin-top: 35vh;
  margin-left: 8vh;
  margin-right: 8vh;

  &.hasLists {
    margin-top: 23vh;
  }
}

.lists {
  margin-top: 1rem;
}
