$FULL_HD_WIDTH: 1644px;
$HD_WIDTH: 1096px;

.centered {
  margin: 0 auto;
  width: $HD_WIDTH;

  &.hasSidebar {
    margin: 0 auto 0 0.375rem;
  }

  &.fullhd {
    width: $FULL_HD_WIDTH;
  }
}
