@use '../../styles/colors';

.loadingIndicator {
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  box-sizing: border-box;
  width: 4rem;
  height: 4rem;
  animation: rotate 1s linear infinite;
  border-radius: 50%;
  border-top: 0.2rem solid colors.$gray05;
  border-left: 0.2rem solid colors.$gray05;
  border-right: 0.2rem solid transparent;
}
