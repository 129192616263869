@use './variables';
@use '../styles/colors';
@use './viewButtons';

.viewContainer {
  width: auto;
  height: 100%;
  box-sizing: border-box;
  margin-left: variables.$sidebarWidth;
  overflow: hidden;
  position: relative;
}

:global(.lasten) .viewContainer {
  text-transform: uppercase;
  padding-left: 0;
}

.listsWrapper {
  position: relative;
  margin-left: 1rem;

  & > * {
    margin-bottom: 1rem;
    position: relative;
  }
}

:global(.lasten) .listsWrapper {
  margin-left: 1rem;
}

.promotionStripe {
  font-size: 1.125em;
  color: colors.$promotionForeground;
  padding: 1.5rem 0;
  text-align: center;
  height: 13.37rem;

  .promotionList {
    display: block;
    margin-left: 0;
    height: 87%;
    width: 91.2%;
    background-color: colors.$promotionBackground;
  }

  a {
    margin-top: 6%;
  }
}

.scrollButtonsWrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  pointer-events: none;
}

.tabList {
  z-index: 1;
  margin-top: auto;
  margin-bottom: 1rem;
  margin-left: 1rem;

  .tab {
    border-bottom: 0.3125rem solid;
    border-bottom-color: transparent;
    opacity: 0.6;
    padding: 0.33rem 0;
    text-decoration: none;
    margin-right: 1rem;
    color: colors.$gray05;
    font-size: 1rem;

    &:focus,
    &:hover {
      opacity: 1;
    }

    &.activeTab {
      border-bottom-color: colors.$turquoise40;
    }
  }
}

:global(.lasten) .tabList {
  margin-top: 4rem;
  margin-left: 6rem;
  padding-left: 0.5rem;

  .tab {
    color: colors.$white;
  }
}

.buttonPrimary {
  @include viewButtons.common;
}

.buttonPrimary.blocked {
  color: colors.$whiteOpacity20;

  &:focus,
  :global(.hover-styles-enabled) &:hover {
    background: colors.$whiteOpacity20;
    color: colors.$whiteOpacity30;
    -webkit-text-fill-color: colors.$whiteOpacity20;
    outline: colors.$gray05 solid 2px;
  }
}

.buttonSecondary {
  @include viewButtons.common;

  &:focus,
  :global(.hover-styles-enabled) &:hover {
    & svg,
    .buttonIcon {
      & path {
        fill: colors.$gray95;
      }
    }
  }
}
