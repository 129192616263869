@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

.keyboardWrapper {
  position: relative;
  left: -1rem;
}

.loginForm {
  @include typography.m-bold;
  margin-left: 6.042rem;
  width: 22.5rem;
}

.inputWrapper {
  position: relative;
  margin-bottom: 1.333rem;
}

.checkboxLabel {
  font-weight: normal;
}

.loginButton {
  @include typography.m-bold;
  display: block;
  position: relative;
  cursor: pointer;
  margin-top: 2rem;
  padding: 0.6rem 1.5rem;
  border: 0;
  border-radius: 2rem;
  color: colors.$gray05;
  background-color: colors.$whiteOpacity20;

  :global(.hover-styles-enabled) &:hover,
  &:focus {
    outline: none;
    color: colors.$gray95;
    background-color: colors.$gray05;
  }
}

input[type='checkbox'] {
  display: inline-block;
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  vertical-align: middle;
  color: colors.$gray05;
  background-color: colors.$whiteOpacity20;
  width: 1.667rem;
  height: 1.667rem;
  border-radius: 0.25rem;
  border: 0.125rem solid transparent;
  margin-bottom: 0.4rem;
  margin-right: 0.7rem;
  left: -0.3rem;

  :global(.hover-styles-enabled) &:hover,
  &:focus {
    outline: 0;
    box-shadow:
      0 0 0 0.125rem #ffffff,
      0 0 0 0.25rem #121314;
  }

  &:checked {
    color: #000;
    background-color: colors.$turquoise60;
  }

  &:checked:after {
    display: block;
    content: ' ';
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1.000000, 1.000000)' fill='%23131415'%3E%3Cpath d='M11.8047497,0.202772467 C12.0650834,0.473137833 12.0650834,0.911480214 11.8047497,1.18184766 L4.47141291,8.79723999 C4.21105921,9.06758667 3.78895196,9.06758667 3.52859827,8.79723999 L0.19526232,5.3357018 C-0.0650874401,5.06533436 -0.0650874401,4.62699197 0.19526232,4.35662453 C0.455612014,4.08626401 0.877721265,4.08626401 1.13807496,4.35662453 L4,7.32864486 L10.8619484,0.202772467 C11.1222821,-0.0675908223 11.544416,-0.0675908223 11.8047497,0.202772467 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
  }
}

.error {
  margin-top: 2.333rem;
  color: colors.$gray95;
  background-color: colors.$red20;
  border-radius: 0.333rem;
  padding: 1rem 1.333rem;
  font-weight: normal;
  vertical-align: middle;

  svg {
    height: 1.3rem;
    vertical-align: top;
    padding-top: 0.2rem;
    padding-right: 0.4rem;

    path {
      fill: colors.$gray95;
    }
  }
}
