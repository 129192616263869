@use 'src/client/styles/typography';
@use 'src/client/styles/colors';

.button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  border-radius: 6.25rem;
  font: inherit;
  cursor: pointer;
  margin: 0;
  max-width: 100%;
  padding: 0.88rem 2.5rem;
  text-decoration: none;
  text-align: center;
  text-wrap: nowrap;
}

@mixin defaultBackground {
  background: colors.$whiteOpacity20;
}

@mixin defaultColor {
  color: colors.$gray05;
}

@mixin defaultHoverBackground {
  background: colors.$gray05;
}

@mixin defaultHoverColor {
  color: colors.$gray95;
}

@mixin defaultDisabledBackground {
  background: colors.$whiteOpacity30;
}

@mixin defaultDisabledColor {
  color: colors.$whiteOpacity40;
}

.type-default {
  @include defaultBackground;
  @include defaultColor;

  &:focus:not(.disabled),
  :global(.hover-styles-enabled) &:hover {
    @include defaultHoverBackground;
    @include defaultHoverColor;
  }
}

.type-default .disabled {
  cursor: not-allowed;
  @include defaultDisabledBackground;
  @include defaultDisabledColor;
}

.type-text {
  background: none;
  @include defaultColor;

  &:focus:not(.disabled),
  :global(.hover-styles-enabled) &:hover {
    @include defaultHoverBackground;
    @include defaultHoverColor;
  }
}

.size-md {
  @include typography.l-bold;

  .icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.size-sm {
  @include typography.m-bold;

  .icon {
    width: 1.75rem;
    height: 1.75rem;
  }

  .icon-left {
    margin-right: 0.625rem;
  }

  .icon-right {
    margin-left: 0.625rem;
  }
}
