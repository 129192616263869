@use '../styles/colors';

.heroPlay {
  .geoBlockedIcon {
    display: none;
  }
}

.heroPlayBlocked {
  &:focus,
  :global(.hover-styles-enabled) &:hover {
    background: colors.$whiteOpacity20;
    color: colors.$whiteOpacity30;
  }
}

.heroPlay.heroPlayGeoBlocked {
  background: colors.$lastenDisabledHeroBackground;

  .geoBlockedIcon {
    position: relative;
    top: 0px;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-image: url('https://images.cdn.yle.fi/image/upload/icon-georestricted-tw.svg.svg');
  }

  & svg {
    display: none;
  }

  :global(.lasten) &:focus,
  :global(.lasten):global(.hover-styles-enabled) &:hover {
    background: colors.$lastenDisabledHeroBackground;
    outline: 4px solid colors.$white;
  }
}
