@use 'src/client/View/variables';
@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

$seasonElementHeight: 3.1667rem;
$seasonsPageSize: 6;

$textDefault: colors.$gray05;
$textNegative: colors.$gray95;
$actionPrimary: colors.$gray05;
$actionSecondary: colors.$whiteOpacity05;
$actionSecondaryVariant: colors.$whiteOpacity20;
$border: colors.$gray05;
$highlight: colors.$turquoise40;
$highlightLive: colors.$red50;
$highlightWarning: colors.$orange60;
$playerProgressbarBackground: #ffffff4d;

.episodesView {
  display: flex;
  height: 100%;
  padding-top: 6rem;
  padding-left: calc(variables.$sidebarWidth + 0.37rem);
  padding-right: 0.58rem;

  .episodesListContainer {
    margin-left: 2rem;
  }
}

.seasonsList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;

  overflow: hidden;
  height: ($seasonElementHeight + 1rem) * $seasonsPageSize - 1rem;
}

.seasonContainer:not(:first-child) {
  margin-top: 1rem;
}

.seasonButton {
  border: 0;
  background: transparent;
  outline: none;
  font-family: inherit;

  @include typography.l-regular;
  text-decoration: none;
  color: $textDefault;
  text-align: left;

  width: 21rem;
  height: $seasonElementHeight;
  padding: 0.4rem 1.331rem;
  border-radius: 0.5rem;

  &:focus,
  &:hover {
    background-color: $actionPrimary;
    color: $textNegative;
  }
}

.seasonButtonActive {
  background-color: $actionSecondaryVariant;
}

.scrollButtonSeasons {
  position: relative;

  button {
    position: absolute;
    left: 50%;
  }

  &Up {
    top: -4rem;
  }

  &Down {
    bottom: -4rem;
  }
}

.episodesTitle {
  @include typography.xl-bold;
  color: $textDefault;
  margin: 0 0 3rem;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.episodesList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.episodeContainer:not(:first-child) {
  margin-top: 1rem;
}

.episodesListInfinite {
  .episodeContainer {
    // episode height + .episodesList gap
    transform: translateY(calc(-1 * (100% + 1rem)));
  }
}

.episodeLoading {
  padding: 0;
}

$infoIconSize: 3rem;

.episodeInfoLink {
  visibility: hidden;
  margin-left: auto;
  position: absolute;
  right: 0;
  top: calc(50% - ($infoIconSize / 2));
}

.episodeInfoIcon {
  width: $infoIconSize;
  height: $infoIconSize;
  transform: rotate(180deg);
  path {
    fill: $actionPrimary;
  }
}

.episodeLink {
  display: flex;
  text-decoration: none;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  position: relative;
  width: 50.66667rem;

  &:focus,
  &:hover {
    .episodeThumbnail,
    .episodeLoadingAnimation {
      color: $border;
    }
  }

  &Focused, // class for last focused episode when seasons list is focused
  &:focus,
  &:hover {
    background-color: $actionSecondary;

    .episodeInfoLink {
      visibility: visible;
    }
  }
}

.episodeDetails {
  color: $textDefault;
  display: flex;
  flex-direction: column;
  width: 26.25rem;
  margin-left: 1.66667rem;

  .episodeDescription,
  .episodeLabelContainer {
    margin-top: 0.66667rem;
  }
}

.episodeTitle {
  @include typography.l-bold;
  margin: 0;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.episodeDescription {
  @include typography.m-regular;
  margin: 0;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.episodeProgressContainer {
  width: 90%;
  height: 0.1875rem;
  background-color: $playerProgressbarBackground;
  display: block;
  position: absolute;
  bottom: 0.65rem;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.episodeProgressbar {
  height: 100%;
  background-color: $highlight;
  display: inherit;
}

.episodeProgressbarBroadcast {
  background-color: $highlightLive;
}

$thumbnail-width: 17.75rem;

.episodeImage {
  position: relative;
  width: $thumbnail-width;
}

.episodeThumbnail {
  position: relative;
  color: transparent;
  padding: 0;
  height: 10rem;
  width: $thumbnail-width;
  border-style: solid;
  border-width: 0.33rem;
}

.episodeLabelContainer {
  display: flex;
  margin: 0;
}

.episodeLabel {
  @include typography.m-regular;

  &:not(:first-child) {
    margin-left: 1rem;
  }
}

.episodeLabelExpiring {
  color: $highlightWarning;
}

.episodesListContainer {
  position: relative;
}
