@use '../../View/variables';
@use '../../styles/colors';
@use '../../styles/typography';

.profilesList {
  list-style-type: none;
  white-space: nowrap;
  overflow-x: scroll;
  max-width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  padding: 2.333rem 0 0;
  margin: 0 0 6.083rem;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  & > .profileListItem {
    margin: 0;

    & ~ .profileListItem {
      margin-left: 1.667rem;
    }
  }
}

.profileView {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  color: colors.$gray05;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include typography.three-xl-bold;
  margin: 7.5rem 0 2.333rem;
  padding-bottom: 1.667rem;
}

.label {
  text-align: center;
  @include typography.l-regular;
}

.addButton {
  width: 100%;
  height: 100%;

  font-size: 5rem;

  background-color: colors.$whiteOpacity20;
  color: colors.$gray05;

  background-clip: padding-box;
  border-radius: 1.667rem;
  border: 0.333rem solid transparent;

  :global(.hover-styles-enabled) &:hover,
  &:focus {
    outline: none;
    border-color: colors.$gray05;
  }
}

.buttonLabel {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include typography.l-regular;
}

.profileButtonContainer {
  $size: 7.833rem;
  height: $size;
  width: $size;

  margin: 0 1.167rem 1rem;

  position: relative;

  & > button {
    &:focus,
    :global(.hover-styles-enabled) &:hover {
      transform: scale(1.212);

      & ~ .profileButtonEditIcon {
        visibility: visible;
      }
    }
  }
}

.profileButtonEditIcon {
  position: absolute;
  visibility: hidden;
  bottom: -0.5rem;
  right: -0.5rem;

  color: colors.$gray95;
  background-color: colors.$gray05;

  height: 1.328rem;

  padding: 1rem;

  border-radius: 1rem 0 1.667rem 0;

  &.hidden {
    display: none;
  }
}
