@use '../../styles/colors';

@mixin focusWithInParent {
  background-color: colors.$gray05;
  top: 0.3rem;
  width: 1.58rem;
  height: 1.58rem;
}

.playhead {
  position: absolute;
  top: 0.15rem;
  transform: translate(-50%, -50%);
  width: 0.79rem;
  height: 0.79rem;
  border-radius: 50%;
  background-color: colors.$turquoise40;
}

:global(.lasten) .playhead {
  background-color: colors.$yleDarkYellow;
}

:global(.lasten) .focusWithIn {
  @include focusWithInParent;
}

.focusWithIn {
  @include focusWithInParent;
}

.live {
  background-color: colors.$red50;
}
