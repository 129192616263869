@use '../../styles/colors.scss';

.background {
  pointer-events: none;
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.4s,
    visibility 0.4s;
  background-image: linear-gradient(
    to bottom,
    colors.$blackOpacity40,
    colors.$blackOpacity60
  );
}

.isVisible {
  opacity: 1;
  visibility: visible;
}
