@use '../../styles/colors';

.overlay {
  margin: 0 auto;
  color: colors.$white;
  line-height: 1;
  opacity: 0;
  transition: all 0.4s;
  pointer-events: none;
  width: 94vw;
  height: 100vh;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.isVisible {
  opacity: 1;
}
