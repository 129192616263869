@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

.root {
  color: colors.$white;
  background-color: colors.$gray80;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.contentContainer {
  display: flex;
  flex-direction: column;
}

.backgroundContainer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.videoBackground,
.imageBackground {
  position: absolute;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  object-fit: cover;
}

.videoBackgroundHD {
  width: 1280px;
  height: 720px;
}

.videoBackgroundFullHD {
  width: 1920px;
  height: 1080px;
}

.videoBackgroundHidden,
.imageBackgroundHidden {
  opacity: 0;
}

.logoContainer {
  position: absolute;
  top: 3.75rem;
  right: 6.333rem;

  svg {
    height: 1.666rem;
  }
}

.actions {
  position: relative;
  display: flex;
  margin: 2.083rem 6.375rem 0;
  justify-content: center;

  button {
    width: 16.5rem;
  }

  button:first-child {
    margin-right: 0.5rem;
  }
  button:last-child {
    margin-left: 0.5rem;
  }
}

.languageLink {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 1.666rem;
}

.cookieLink {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 1rem 0 0;
  @include typography.xs-regular;
  color: #bdc9cb;
}
