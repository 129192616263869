@use '../../styles/colors';
.radioControls {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  bottom: 3.6rem;
  left: 6rem;
}

.channelLogo {
  height: 2.21rem;
  margin-right: 20px;
}

.button {
  background-color: colors.$whiteOpacity20;
  color: colors.$gray05;
  padding: 0.5rem 1rem;
  border: 0;
  outline: 0;
  border-radius: 0.333rem;
  font-size: 1rem;
  font-weight: 700;

  &:focus,
  :global(.hover-styles-enabled) &:hover {
    color: colors.$gray95;
    background-color: colors.$gray05;
  }
}
