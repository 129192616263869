@use '../styles/colors';
@use '../styles/typography';
@use '../View/variables' as viewVariables;
@use './variables';

.sidebar {
  width: viewVariables.$sidebarWidth;
  height: 100%;
  color: colors.$gray05;
  transition:
    width variables.$transitionDuration,
    background-color 0.1s;
  position: absolute;
  top: 0;
  left: 0;
  @include typography.m-regular;
  line-height: normal;

  // enable hardware acceleration
  transform: translateZ(0);

  &:global(.sidebarMenuContainer) {
    cursor: pointer;
  
    &:global(.sidebarOpen) {
      width: variables.$sidebarOpenWidth;
    }
  }

  &:global(.sidebarHidden) {
    display: none;
  }
}

.sidebarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    90deg,
    colors.$gray95 43.19%,
    rgba(19, 20, 21, 0) 100%
  );
  transition:
    opacity variables.$transitionDuration,
    visibility variables.$transitionDuration;
  opacity: 0;
  visibility: hidden;
  width: 65%;

  &:global(.sidebarOpen) {
    opacity: initial;
    visibility: initial;
  }
}

:global(.hover-styles-enabled) {
  .sidebar:not(:global(.sidebarOpen)):hover {
    background-color: colors.$gray80;
  }
}
