.promoView {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #ffd959;
}

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
