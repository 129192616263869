@use './base-card';
@use './base-list';
@use './variables';

// square card strips are essentially large strips with different card-dimensions and show
// less information.

:global(.square-card-strip) {
  .card {
    width: variables.$stripCardHeight;

    .content {
      .labelWrapper,
      .primaryText,
      .progressbarWrapper,
      .secondaryText {
        display: none;
      }

      background: unset;
    }
  }
}
