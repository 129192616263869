@use '../../styles/colors.scss';
@use './variables';

@mixin default {
  border: variables.$cardBorderWidth solid colors.$white;
  outline: none; // check if necessary, App.tsx defines similar
}

@mixin coverstrip {
  object,
  :global(.loadingIndicator) {
    border: variables.$cardBorderWidth solid colors.$white;
  }

  .primaryText {
    transform: scale(1.1);
  }
}
