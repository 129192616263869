@use './colors';

body {
  font-family: 'Yle Next', sans-serif;
}

body.lasten {
  font-family: 'Nunito', sans-serif;
}

a,
button {
  cursor: pointer;
}

a:focus {
  outline: none;
}
