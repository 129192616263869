@use '../../styles/colors';
.seekIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 3.5rem;
  height: 3.5rem;
  padding: 1.5rem;
  border-radius: 50%;
  background-color: colors.$blackOpacity80;
  color: colors.$gray05;
  transform: translate(-50%, -50%);
}

.icon {
  transform: translateY(-8%);
}
