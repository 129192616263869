@use '../../styles/colors';

.progressIndicator {
  position: absolute;
  border-radius: 0.1875rem 0 0 0.1875rem;
  background-color: colors.$turquoise40;
  height: 100%;
}
:global(.lasten) .progressIndicator {
  background-color: colors.$yleDarkYellow;
}

.live {
  background-color: colors.$red50;
}
