.subtitle {
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  transition: bottom 0.3s;
  font-family: Tiresias, TiresiasScreenfont, sans-serif;
  top: auto;
  bottom: 3.125rem;
}

.playerUiVisible {
  bottom: 11.875rem;
}

.hasLists {
  bottom: 21.875rem;
}

.container {
  width: 84%;
  text-align: center;
  margin: 0 auto;
  white-space: pre-line;
}
