$black: rgba(0, 0, 0, 1);

$blackOpacity80: rgba(0, 0, 0, 0.83); // Dialog overlay
$blackOpacity70: rgba(0, 0, 0, 0.7);
$blackOpacity60: rgba(0, 0, 0, 0.6);
$blackOpacity50: rgba(0, 0, 0, 0.5);
$blackOpacity40: rgba(0, 0, 0, 0.4);
$blackOopacity09: rgba(0, 0, 0, 0.09);

$gray95: #131415; // Background / Negative text
$gray90: #1f2123; // Background variant / Dialog background
$gray80: #292b2d; // Input background / Info secondary background
$gray60: #4a4f54; // Separator
$gray50: #949697; // Input placeholder text
$gray30: #d8d9db; // Info primary background
$gray20: #0b3f4b; // Highlight background
$gray05: #f8f9fa; // Primary text / Border / Primary action

$green40: #03e592; // Success

$orange60: #ff9c31; // Warning

$red50: #e90e43; // Live
$red40: #f5517a; // Error
$red20: #f99fb5; // Error background

$turquoise40: #29ccde; // Highlight
$darkTurquoise: #0b818e;
$turquoise60: #009cb5; // checkbox checked background

$yleDarkYellow: #ffbe00; // yleDarkYellow from SDK, this is technically importable

$white: #ffffff;
$whiteOpacity70: rgba(255, 255, 255, 0.85); // High emphasis text
$whiteOpacity60: rgba(
  255,
  255,
  255,
  0.71
); // Primary action variant / Medium emphasis text
$whiteOpacity50: rgba(255, 255, 255, 0.59); // Control background
$whiteOpacity40: rgba(255, 255, 255, 0.3); // Disabled text / Disabled border
$whiteOpacity30: rgba(255, 255, 255, 0.21); // Disabled control background
$whiteOpacity20: rgba(255, 255, 255, 0.17); // Secondary action variant
$whiteOpacity05: rgba(255, 255, 255, 0.08); // Secondary action

// ==== by usage below ====

$activeCardBackground: rgba(17, 180, 199, 0.6);

$lastenDisabledHeroBackground: rgba(41, 43, 45, 0.4);

$placeholderBg: rgba(204, 204, 204, 0.37); // LoadingCard background

$progressbarBackground: rgba(192, 192, 192, 0.4);
$progressbarForeground: $turquoise40;
$progressbarForegroundLive: #da1f26;

$promotionForeground: #eef2f8;
$promotionBackground: rgba(41, 204, 222, 0.2);

$skipButtonBackground: rgba(
  86,
  88,
  92,
  0.8
); // promotion.button.background in old colors file
