@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

.container {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.innerContainer {
  display: flex;
}

.opacity {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: colors.$blackOpacity80;
}

.messageContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.digitsContainer {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  justify-content: center;
}

.digits {
  margin-top: 1rem;
  background: colors.$blackOpacity60;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  color: colors.$gray05;
}

.number {
  @include typography.four-xl-regular;
  padding: 0.3rem 0.5rem 0.3rem 0.6rem;
}

.unit {
  @include typography.s-regular;
  padding-bottom: 0.3rem;
  color: colors.$gray05;
}

.infoText {
  @include typography.xl-bold;
  color: colors.$gray05;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
