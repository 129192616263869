@use '../styles/colors';
@use '../styles/button';
@use '../styles/typography';

.header {
  margin-top: 0rem;
}

.checkboxLabel {
  @include typography.l-bold;
  line-height: 140%;
  vertical-align: middle;
}

.actions {
  margin: 0.583rem 0;
  button:focus {
    background: colors.$gray05;
    color: colors.$gray95;
  }
}

.action {
  background: colors.$gray60;
  color: colors.$gray05;
  margin-left: 1.333rem;
  text-decoration: none;
  text-align: center;
  border-radius: 6.25rem;
  padding: 0.583rem 1.666rem;
  font-size: button.$font-size;
  line-height: button.$line-height;
  font-weight: button.$font-weight;
}

:global(.hover-styles-enabled) .action:hover {
  background: colors.$gray05;
  color: colors.$gray95;
  path {
    fill: colors.$gray95;
  }
}

.editContainer {
  margin-right: 4rem;
}

.editConsentBody {
  font-size: 1.333rem;
  margin: 0.973rem 0 1.2rem 1.958rem;
  p {
    margin: 0;
  }
}

input[type='checkbox'] {
  appearance: none;
  background-color: colors.$gray05;
  border: 0.125rem solid colors.$gray95;
  box-sizing: border-box;
  border-radius: 0.25rem;
  padding: 0.3rem 0.2rem 0.2rem;
  display: inline-block;
  position: relative;
  margin: 0 0.625rem 0 0;
  width: 1.333rem;
  height: 1.333rem;
  vertical-align: middle;
}

input[type='checkbox']:focus {
  box-shadow:
    0px 0px 0px 0.125rem colors.$gray05,
    0px 0px 0px 0.25rem colors.$gray95;
  outline: none;
}

input[type='checkbox']:checked {
  background-color: colors.$turquoise60;
}

input[readonly]:checked {
  background-color: colors.$gray60;
}

input[type='checkbox']:checked:after {
  display: block;
  content: ' ';
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1.000000, 1.000000)' fill='%23F8F9FA'%3E%3Cpath d='M11.8047497,0.202772467 C12.0650834,0.473137833 12.0650834,0.911480214 11.8047497,1.18184766 L4.47141291,8.79723999 C4.21105921,9.06758667 3.78895196,9.06758667 3.52859827,8.79723999 L0.19526232,5.3357018 C-0.0650874401,5.06533436 -0.0650874401,4.62699197 0.19526232,4.35662453 C0.455612014,4.08626401 0.877721265,4.08626401 1.13807496,4.35662453 L4,7.32864486 L10.8619484,0.202772467 C11.1222821,-0.0675908223 11.544416,-0.0675908223 11.8047497,0.202772467 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
}
