@use 'src/client/View/variables';
@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

$textDefault: colors.$white;

.root {
  color: $textDefault;
  padding: 3.5rem 0 0 calc(variables.$sidebarWidth + 0.96rem);

  p {
    margin: 0.5rem 0;
  }
}

.title {
  @include typography.xl-bold;
  line-height: 1.5;
}

.content {
  display: flex;
}

.logo {
  position: absolute;
  top: 3.7rem;
  right: 6.3rem;
  height: 1.667rem;
}
