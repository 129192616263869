@use '../../styles/colors';
@use '../../styles/typography';

.root {
  width: 100%;
  height: 100%;
  color: colors.$gray05;
}

.containerKeyboard {
  > div {
    margin: 0;
  }

  margin-right: 4rem;
}

.inputLabel {
  margin-bottom: 2.667rem;
  display: block;
}

.buttonSave {
  margin-right: 42px;
}

.buttonWrapper {
  button:not(:last-child) {
    margin-right: 1.375rem;
  }
}

.form {
  width: 32.75rem;

  @include typography.xl-bold;
}

.inputWithCursor {
  position: relative;
  overflow: hidden;

  .realInput:focus + .fakeInput {
    color: colors.$gray95;
  }
}

$inputPaddingHorizontal: 1.333rem;
$inputPaddingVertical: 0.667rem;
$inputMarginTop: 1.667rem;

.realInput {
  $borderWidth: 0.083rem;

  outline: none;
  border: colors.$gray05 solid $borderWidth;
  color: transparent;
  background-color: colors.$whiteOpacity20;
  padding: $inputPaddingVertical $inputPaddingHorizontal;
  margin-top: $inputMarginTop;
  caret-color: transparent;
  box-sizing: border-box;
  width: 100%;

  &:focus {
    background-color: colors.$gray05;
    border-color: colors.$gray95;
    caret-color: transparent;
  }

  &.isErrored {
    border-color: colors.$red40;
  }

  @include typography.xl-bold;
}

.fakeInput {
  color: colors.$gray05;
  position: absolute;
  left: $inputPaddingHorizontal;
  top: calc($inputPaddingVertical + $inputMarginTop);
  animation: blinkingCursor 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite;
  text-wrap: nowrap;

  @keyframes blinkingCursor {
    50% {
      border-right: 0.125rem solid currentColor;
    }
  }
}

.errorMessage {
  color: colors.$gray95;
  background-color: colors.$red40;
  padding: 1rem 1.333rem;
  border-radius: 0.333rem;
  display: none;

  &.isErrored {
    display: block;
  }

  @include typography.m-regular;
}

.iconList {
  list-style: none;
  margin: 0.5rem 0.5rem 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 67rem;
}

.containerEditName {
  padding-top: 13.333rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.containerSelectIcon {
  padding-top: 3.458rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerIcon {
  padding: 0.833rem;
  margin: 0.5rem;
  position: relative;

  $size: 7.833rem;
  width: $size;
  height: $size;

  &Focused,
  :hover {
    transform: scale(1.143);
  }

  &Active > button {
    outline-offset: 0.025rem;
    outline: 0.333rem solid colors.$whiteOpacity20;
  }
}

.header {
  margin: 0 0 3rem;
  @include typography.xl-bold;
}
