@use '../../styles/colors';
@use '../../styles/typography';

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.qrCode {
  width: 19.1rem;
  height: 19.1rem;
  border-radius: 10.67px;
  margin-top: 2.33rem;
}

.columnLeft {
  @include typography.l-regular;
  margin-right: 5.33rem;
  width: 19.1rem;
  text-align: center;
}

.columnRight {
  display: flex;
  flex-direction: column;
  width: 32.75rem;
  padding-top: 2.33rem;

  ul {
    margin-top: 0;
  }

  li {
    @include typography.l-regular;
    margin-bottom: 1.5rem;
  }

  li:last-child {
    margin-bottom: 1.8rem;
  }

  div {
    @include typography.l-regular;
  }
}

.textBold {
  @include typography.l-bold;
}

.bat {
  padding: 0 1.18rem;
}
