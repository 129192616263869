@use '../../styles/animations';
@use '../../styles/colors';

.loadingIndicator {
  width: 100%;
  height: 100%;
  background: colors.$placeholderBg;
}

.loadingAnimation {
  width: 100%;
  height: 100%;
  @include animations.loading-indicator();
}
