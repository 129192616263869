@use '../../styles/colors';
@use './variables.scss';
.progressBar {
  display: inline-block;
  height: 1.35rem;
  padding-top: 0.4rem;
  padding-left: 0.85rem;
  padding-right: 0.85rem;
  cursor: pointer;
  user-select: none;
  touch-action: none;
  width: variables.$progressBarWidthPercentage;
  vertical-align: middle;
  overflow: hidden;
}

.progressEnabled {
  :global(.hover-styles-enabled) &:hover a {
    top: 0.166rem;
    height: 0.666rem;
    span {
      top: 0.3rem;
      width: 1.58rem;
      height: 1.58rem;
      background-color: colors.$gray05;
    }
  }
}
