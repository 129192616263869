@use '../styles/colors';
@use '../styles/typography';

.dialog {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  background: colors.$blackOpacity70;

  &.open {
    visibility: visible;
  }
}

.contentWrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 30.21%;
  padding-left: 2.666rem;
  font-family: inherit;
  color: colors.$gray05;
  background: colors.$blackOpacity80;
}

.content {
  margin-top: 13.958rem;
  text-align: left;
  height: 100%;

  h2 {
    @include typography.xl-bold;
    margin: 0 0 1.333rem 0;
    width: 77.93%;
  }
  p {
    @include typography.m-regular;
    width: 77.93%;
  }

  button {
    @include typography.s-bold;
    cursor: pointer;
    color: colors.$gray05;
    display: inline-block;
    border-radius: 6.25rem;
    margin: 2.08rem 2rem 0 0;
    padding: 0.333rem 1rem;
    background: colors.$whiteOpacity20;
    outline: none;
    border: none;

    :global(.hover-styles-enabled) &:hover {
      background: colors.$gray05;
      color: colors.$gray95;
    }
    &:focus {
      background: colors.$gray05;
      color: colors.$gray95;
    }
  }
}
