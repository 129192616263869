@use './styles/colors';
@use './styles/typography';

.noEpisodes {
  @include typography.three-xl-regular;
  background: colors.$gray95;
  color: colors.$gray05;
  font-family: inherit;
  max-width: 36rem;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    margin: 0;
    margin-bottom: 1.66rem;
  }

  svg {
    display: inline-block;
    width: 7.4rem;
    height: 7.4rem;
    vertical-align: top;
  }
}
