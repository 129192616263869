
@mixin loading-indicator() {
  background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.15),
      transparent
    )
    0 50%;
  // background-size should be set after background or it will be overridden.
  background-size: 200% 200%;

  @keyframes loading-indicator {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  animation: loading-indicator 1.5s ease infinite;
}
