@use '../styles/colors';
@use '../styles/typography';

.container {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: colors.$blackOpacity80;
  z-index: 1;
  align-items: center;
  justify-content: center;
}

.content {
  width: 45%;
  color: colors.$white;
  max-height: 70%;
  margin-bottom: 4rem;
}

.title {
  font-size: 1.33rem;
  font-weight: 700;
  line-height: 1.8rem;
  margin-bottom: 2rem;
}

.description {
  font-size: 1.17rem;
  line-height: 1.58rem;
  max-height: 27rem;
  overflow: hidden;
}

.buttonWrapper {
  position: absolute;
  bottom: 3.2rem;
  width: 45%;
  text-align: center;
}

.button {
  all: unset;
  @include typography.l-bold;
  line-height: 3rem;
  padding: 0 1.9rem;
  line-height: 3rem;
  border-radius: 1.5rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  border: 0;
  outline: 0;
  text-decoration: none;
  background: colors.$whiteOpacity20;
  color: colors.$gray05;

  :global(.hover-styles-enabled) &:hover,
  &:focus {
    background: colors.$gray05;
    color: colors.$gray95;
    -webkit-text-fill-color: colors.$gray95;
  }
}
