@use '../../styles/colors.scss';
@use '../../styles/animations.scss';
@use './card-focus-styles';
@use './variables';

@mixin common {
  .title {
    display: block;
    color: colors.$white;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.3rem;
    height: 2.375rem;
    white-space: pre-wrap;

    &:empty {
      @include animations.loading-indicator();
      width: 34rem;
    }
  }

  .list {
    display: flex;
    flex: 0 0 100%;
    overflow: visible;
    flex-wrap: nowrap;
    margin-left: - variables.$cardBorderWidth;

    a {
      text-decoration: none;
      flex: 0 0 auto;
    }

    a:focus .card,
    .card:focus {
      @include card-focus-styles.default;
    }
  }

  .card {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    border: 0.333rem solid transparent;

    .content {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  object {
    width: 100%;
    height: 100%;
    background: rgba(256, 256, 256, 0.8);
    opacity: 1;
    transition: opacity variables.$transitionTime linear;
  }

  object:not([data]) {
    opacity: 0;
  }
}
