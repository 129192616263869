@use './variables';
@use '../styles/colors';

.sidebarItemTitle {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity variables.$transitionDuration,
    visibility variables.$transitionDuration;

  :global(.sidebarIconGroup) & {    
    margin-left: 2.883rem;
  }
}

.sidebarItemIcon {
  width: 1.3rem;
  height: 1.3rem;

  // current page's underline under the sidebar item
  &::after {
    content: '';
    position: absolute;
    top: 1.666em;
    left: 0;
    width: 100%;
    height: 0.25rem;

    .sidebarItemCurrent > & {
      background-color: colors.$turquoise40;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.sidebarMenuItem {
  position: relative;
  display: block;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  text-decoration: none;
  color: inherit;
  user-select: none;
  opacity: variables.$itemOpacityInitial;
}

:global(.sidebarMenuContainer) {
  &:global(.sidebarOpen) {
    .sidebarItemTitle {
      opacity: initial;
      visibility: initial;
    }
  }
}

.sidebarProfileIcon {
  margin-left: -0.5rem;
  background-color: colors.$gray60;
  border: 0.125rem solid transparent;
  width: 2.3625rem;
  height: 2.3625rem;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;

  font-size: 1.125rem;
  line-height: 1.6875rem;
  
  // Initials inside the profile-icon
  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
  }
}

.sidebarItemIcon, .sidebarProfileIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

:global(.hover-styles-enabled)
.sidebarMenuItem:hover,
.sidebarMenuItem:focus {
  font-weight: 700;
  opacity: 1;

  .sidebarProfileIcon {
    border-color: colors.$gray05;
  }
}

