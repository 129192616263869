@use 'src/client/styles/typography';
@use 'src/client/styles/colors';

.root {
  padding: 3.5rem 6.167rem 0;

  color: colors.$gray05;
}

.title {
  @include typography.xl-bold;
  margin: 0 0 4.5rem;
}

.iconWrapper {
  $size: 11.667rem;
  width: $size;
  height: $size;
}

.iconLabel {
  margin-left: 10.167rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @include typography.l-bold;
}

.field {
  padding-bottom: 1rem;
  display: flex;
  align-items: center;

  &.lastFieldButton {
    padding-bottom: 2rem;
  }
}

$buttonLabelWidth: 6.5rem;

.fieldButtonLabel {
  width: $buttonLabelWidth;
  display: inline-block;
}

.info {
  padding-bottom: 3.333rem;
  display: flex;

  @include typography.m-regular;
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
  max-width: 39.25rem;

  @include typography.l-regular;
}

.fieldButton,
.info {
  width: 32.75rem;
}

.fieldButton {
  padding: 0.333rem 1.333rem;
  border: none;
  border-radius: 0.667rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include typography.l-bold;
  line-height: 2.667rem;
  color: colors.$gray05;
  background-color: colors.$whiteOpacity20;

  :global(.hover-styles-enabled) &:hover,
  &:focus {
    background-color: colors.$gray05;
    color: colors.$gray95;
  }

  &:focus-visible {
    outline: none;
  }
}

.fieldSpan {
  padding-left: 1.333rem;
}

.info,
.buttons {
  padding-left: $buttonLabelWidth;
}

.content {
  display: flex;
}

.buttons {
  button {
    margin-right: 1rem;
  }

  display: flex;
}

.infoParentName {
  @include typography.m-bold;
}

.infoQR {
  $size: 7.8333rem;
  min-width: $size;
  min-height: $size;
  margin-right: 1rem;
}

.infoQRSvg {
  width: 100%;
  height: 100%;
  border-radius: 0.333rem;
}

.infoText {
  margin: auto 0;
}

.loggingOut {
  display: none;
}
