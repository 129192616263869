@use '../styles/colors';

.devicePage {
  color: colors.$gray05;
  margin-left: 6.25rem;
  margin-top: 3rem;
}

.info {
  margin: 0.7rem;
  text-transform: capitalize;
}
