@use '../../styles/colors.scss';

.playerProgress {
  pointer-events: all;
  font-size: 1rem;
}

.position {
  display: inline-block;
  line-height: 1.625rem;
  width: 6.5%;
  vertical-align: middle;
}
