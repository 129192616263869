.logoWrapper {
  position: absolute;
  top: 3.75rem;
  left: 0;
  width: 100%;
  text-align: right;

  svg {
    margin: 0.3125rem;
    width: auto;
    height: 2.3125rem;
  }
}
