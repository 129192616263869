@use '../../styles/colors';

.qualityLabel {
  position: absolute;
  top: 12.6rem;
  right: 6rem;
  font-size: 1.667rem;
  color: colors.$gray50;
}

.resolution {
  display: inline-block;
}

.icon {
  display: inline-block;
  height: 2.667rem;
  vertical-align: middle;
  margin-left: 0.75rem;

  path {
    fill: colors.$gray50;
  }
}
