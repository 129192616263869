@use 'src/client/View/variables';
@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

$textColor: colors.$gray05;

.root {
  color: $textColor;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: radial-gradient(
    92.55% 92.55% at 51.46% -12.27%,
    rgba(35, 148, 160, 1) 0%,
    rgba(20, 21, 22, 1) 100%
  );
}

.viewContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 7.5rem 6.333rem 0 6.333rem;
}

.logoWrapper {
  position: absolute;
  top: 3.75rem;
  right: 6.333rem;
}

.logo {
  margin: 0.3125rem;
  height: 2.3125rem;
}

.title {
  margin: 0 0 1.666rem 0;
  text-align: center;
  @include typography.three-xl-bold;
  color: inherit;
}

.actions {
  display: flex;
  justify-content: center;
}

.actionItem {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  justify-content: center;
  align-items: center;

  button {
    border-radius: 1.666rem;
    outline: none;
    cursor: pointer;
    border: 0.3125rem solid transparent;
    margin: 0 0 1rem 0;
    padding: 0;
    width: 7.833rem;
    height: 7.833rem;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: transparent;
    &:focus,
    &:hover {
      border-color: colors.$white;
      scale: 1.13;
    }
  }

  &:first-child {
    margin-right: 1.666rem;
    button {
      background-color: colors.$whiteOpacity20;
      svg {
        width: 3.666rem;
        height: 3.666rem;
      }
    }
  }
}

.label {
  @include typography.l-regular;
  overflow: hidden;
  width: 10.166rem;
  text-align: center;
}

.disabledLabel {
  color: colors.$whiteOpacity40;
}

.actionItemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.416rem;
}

.addSubProfileIcon {
  width: 7.8125rem;
}

.info {
  margin-top: 2.166rem;
  @include typography.m-regular;
  width: 30rem;
  text-align: center;
}
