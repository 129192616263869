@use '../../styles/colors';

.subTitleAudioBack {
  cursor: pointer;
  position: absolute;
  width: 5.375rem;
  background: colors.$gray95;

  &:focus,
  :global(.hover-styles-enabled) &:hover {
    background-color: colors.$gray60;
    color: colors.$gray95;
  }

  height: 100%;
  border: none;
  outline: none;
}

.wrapper {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.icon {
  color: colors.$gray05;
  height: 4.3125rem;
}
