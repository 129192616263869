@use '../View/variables' as viewVariables;
@use './variables';
@use './Sidebar.module.scss';

.sidebarMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-left: 2rem;
  transition: padding-left variables.$transitionDuration;
}

.sidebarMenuGroup {
  width: variables.$sidebarOpenWidth;
  font-size: 1.1rem;

  &:first-child {
    margin-top: 3rem;

  }
  &:last-child {
    margin-bottom: 3rem;
  }

  &:global(.sidebarIconGroup) {
    display: flex;
  }

  &:global(.mainIconMenuGroup) {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
  }
  
  &:global(.sidebarNonIconGroup) {
    margin-top: -1rem;
    padding-bottom: 1.5rem;
  }
}

:global(.sidebarOpen) {
  .sidebarMenu {
    padding-left: 3rem;
  }
}

:global(.sidebarMenuContainer):not(:global(.sidebarOpen)) {
  .sidebarMenuGroup {
    pointer-events: none;
  }
}