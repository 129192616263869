@use '../styles/colors';

.nativeScroller {
  position: relative;
  height: inherit;
  overflow-y: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    background: '#555555';
    width: 1.2rem;
  }
  &::-webkit-scrollbar-thumb {
    background: 'rgba(26, 27, 28, 0.6)';
    border-radius: 10px;
  }
  &.focused::-webkit-scrollbar-thumb {
    background: colors.$gray05;
  }
}

.transformScroller {
  position: relative;
  transition: transform 0.3s;
}
