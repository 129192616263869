@use '../../styles/colors';

$HD_WIDTH: 164px;
$HD_HEIGHT: 92px;

$FULL_HD_WIDTH: 246px;
$FULL_HD_HEIGHT: 138px;

.seekThumbnail {
  object-fit: cover;
  width: $HD_WIDTH;
  height: $HD_HEIGHT;
  border: 0.167rem solid colors.$white;
}

.fullHD {
  width: $FULL_HD_WIDTH;
  height: $FULL_HD_HEIGHT;
}

.displayNone {
  display: none;
}
