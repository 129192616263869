@use '../../styles/colors';

$defaultBackgroundColor: colors.$gray95;
$defaultColor: colors.$gray05;
$focusBackgroundColor: colors.$gray05;
$focusColor: colors.$gray95;

$lastenFocusBackgroundColor: rgb(255, 190, 0);
$lastenFocusColor: rgb(0, 0, 0);

@mixin defaultColors {
  background-color: $defaultBackgroundColor;
  color: $defaultColor;
}

@mixin focusColors {
  background-color: $focusBackgroundColor;
  color: $focusColor;
}

@mixin lastenFocusColors {
  background-color: $lastenFocusBackgroundColor;
  color: $lastenFocusColor;
}

@mixin focusStyles {
  :global(.hover-styles-enabled) &:hover,
  &:focus {
    @include focusColors;
    span {
      svg {
        path {
          fill: $focusColor;
        }
      }
    }
  }

  :global(.hover-styles-enabled):global(.lasten) &:hover,
  :global(.lasten) &:focus {
    @include lastenFocusColors;
    span {
      svg {
        path {
          fill: $lastenFocusColor;
        }
      }
    }
  }
}

@mixin baseButton {
  height: 3.1666rem;
  width: 100%;
  text-align: left;

  font-size: 1.333rem;
  line-height: 1.815rem;
  border-radius: 0.375rem;
  border: 0;
  padding-left: 0.7rem;
  cursor: pointer;
  outline: none;
}

.selectButton {
  @include baseButton;
  @include defaultColors;
  @include focusStyles;
}

.dropdownOptionButton {
  @include baseButton;
  color: $defaultColor;
  background-color: colors.$gray80;
  @include focusStyles;
}

.selectButtonOpen {
  @include defaultColors;
  @include focusStyles;
}

.selectButtonLabel {
  display: inline-block;
  width: 90%;
}

.selectButtonDisabled {
  background-color: $defaultBackgroundColor;
  color: colors.$gray60;
}

@mixin checkmarkIconBase {
  height: 1.5rem;
  vertical-align: middle;
  path {
    fill: currentColor;
  }
}

.checkmarkIcon {
  @include checkmarkIconBase;
  color: colors.$turquoise40;
}

:global(.lasten) .checkmarkIcon {
  @include checkmarkIconBase;
  color: colors.$gray05;
}

.chevron {
  display: inline-block;
  width: 10%;
}

.chevronIcon {
  transform: rotate(180deg);
  height: 78%;
  vertical-align: middle;
}
