@use '../../styles/colors';
.seekPositionLabel {
  display: inline-block;
  color: colors.$gray95;
  background-color: colors.$gray05;
  border-radius: 100px;
  margin-top: 6.7rem;
  padding: 0.4rem 0.5rem;
  font-weight: 700;
  font-size: 1rem;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-top: 0.8rem solid colors.$gray05;
    top: 8.4rem;
    left: 4.5rem;
  }
}

.hasThumbnail {
  margin-top: 0.666rem;
}
