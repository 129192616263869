@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

$textDefault: colors.$white;

h1,
h2,
h3,
ul,
p {
  margin: 0;
  padding: 0;
}

.root {
  color: $textDefault;
  h1 {
    text-align: center;
    @include typography.three-xl-bold;
    margin-top: 3.75rem;
    margin-bottom: 1.75rem;
  }
  h2 {
    @include typography.m-regular;
  }
  h3 {
    @include typography.m-bold;
  }

  p {
    @include typography.m-regular;
  }

  .container {
    margin: 2rem auto 4rem;
    width: 956px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .termsOfUse,
  .serviceDescription,
  .privacyStatement {
    text-decoration: underline;
    @include typography.m-regular;
  }

  .list {
    margin-left: 2rem;
  }

  .controls {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
  }
}
