@use '../../styles/colors';

.sectionHeading {
  position: relative;
  margin-top: 2.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  color: colors.$gray05;
}
