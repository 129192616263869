@use 'src/client/View/variables';
@use '../styles/colors.scss';

.searchView {
  display: flex;
  flex-direction: row;
  padding-left: variables.$sidebarWidth;
  color: colors.$whiteOpacity20;
  background-color: colors.$gray95;
  font-weight: 400;
}

:global(.lasten) .searchView {
  color: colors.$white;
  background-color: colors.$yleDarkYellow;
  font-weight: 700;
  padding-left: 0;
}

.headerWrapper {
  position: absolute;
  padding-top: 3.5rem;
  padding-left: 5rem;
}

.inputWrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.inputElementWrapper {
  width: 16.125rem; /* magic number matches squarekeyboard calculation */
  position: relative;
  top: 0.65rem;

  & span {
    top: 0.65rem;
  }
}

.results {
  display: flex;
  flex: 0 0 50%;
  margin-top: 10rem;
  margin-left: -2rem;

  img {
    width: 100%;
  }

  & :global(.medium-grid) {
    width: unset;
  }

  & .spinnerWrapper {
    position: fixed;
    top: 50%;
    left: 70%;
  }

  & .noResultsWrapper {
    position: fixed;
    top: 23%;
    font-size: 1.5rem;
    font-weight: 700;
    margin-left: 1rem;
  }

  & button {
    &:global(.up),
    &:global(.down) {
      left: 75%;
    }
  }
}
