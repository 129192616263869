@use '../../styles/colors';
.seekIndicator {
  position: absolute;
  background-color: colors.$darkTurquoise;
  height: 100%;
}

:global(.lasten) .seekIndicator {
  background-color: rgb(136, 118, 17);
}

.live {
  background-color: colors.$red50;
}
