.videoControlsWrapper {
  opacity: 0;
  transition:
    visibility 0.4s,
    opacity 0.4s;
  pointer-events: none;
}

.isVisible {
  opacity: 1;
}
