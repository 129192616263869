@use 'src/client/View/variables';
@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

.profilesList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow-x: scroll;
  max-width: 100%;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.profileListItem {
  display: inline-block;
  vertical-align: top;
  text-align: center;
}

.profileView {
  height: 100%;
  width: 100%;
  padding-left: variables.$sidebarWidth;
  box-sizing: border-box;
  color: colors.$white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  @include typography.three-xl-bold;
  margin: 0 0 6.33rem;
}

.label {
  @include typography.l-regular;
  overflow: hidden;
}

@mixin circleButton {
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  border: 0.3125rem solid transparent;
  margin: 0 1.1666rem;
  $buttonSize: 7.8333rem;
  width: $buttonSize;
  height: $buttonSize;
  color: inherit;
  @include typography.three-xl-regular;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-clip: padding-box;

  &:focus,
  &:hover {
    border-color: colors.$white;
  }
}

.profileButton {
  &0 {
    @include circleButton;
    background-color: rgba(102, 157, 225, 0.4);
  }

  &1 {
    @include circleButton;
    background-color: rgba(3, 229, 146, 0.4);
  }

  &2 {
    @include circleButton;
    background-color: rgba(245, 81, 122, 0.4);
  }

  &3 {
    @include circleButton;
    background-color: rgba(186, 114, 213, 0.4);
  }

  &4 {
    @include circleButton;
    background-color: rgba(247, 211, 80, 0.4);
  }

  &5 {
    @include circleButton;
    background-color: rgba(255, 156, 49, 0.4);
  }
}

.clearButton {
  opacity: 0;
  background: none;
  outline: none;
  border: none;
  margin-top: 2rem;

  svg {
    $buttonSize: 1.75rem;
    width: $buttonSize;
    height: $buttonSize;
    padding: 0.625rem;
    border-radius: 50%;
  }

  &.visible,
  &:hover,
  &:focus {
    opacity: 1;
  }

  &:hover,
  &:focus {
    svg {
      background: colors.$gray05;

      path {
        fill: colors.$gray95;
      }
    }
  }
}

.addButton {
  @include circleButton;

  font-size: 5.333rem;

  background-color: colors.$gray60;
  color: colors.$gray05;

  &:focus {
    color: colors.$gray60;
    background-color: colors.$gray05;
  }
}
