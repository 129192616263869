@use 'src/client/View/variables';
@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

.title {
  margin-top: 0;
}

button.languageButton {
  border: 0;
  border-radius: 0.5rem;
  text-align: left;
  margin-bottom: 1.3333rem;
  width: 53%;
  padding: 0.5833rem 1.6666rem;
  position: relative;
  cursor: pointer;
  font-family: inherit;
  @include typography.l-regular;
  display: block;
  text-decoration: none;
  outline: none;

  background: colors.$gray95;
  color: colors.$gray05;

  &:focus,
  :global(.hover-styles-enabled) &:hover {
    background: colors.$gray05;
    color: colors.$gray95;
  }

  & svg {
    position: absolute;
    top: 0.7rem;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}
