@use '../../styles/colors';

.bar {
  display: block;
  position: relative;
  top: 0.333rem;
  height: 0.333rem;
  border-radius: 0.1875rem;
  background-color: colors.$red50;
}

.focusWithIn {
  top: 0.166rem;
  height: 0.666rem;
}

.progressEnabled {
  background-color: colors.$progressbarBackground;
}
