@use 'src/client/styles/typography';
@use 'src/client/styles/colors';

.root {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-clip: padding-box;
  border-radius: 1.667rem;
  border: 0.333rem solid transparent;
  overflow: hidden;
  padding: 0;

  :global(.hover-styles-enabled) &:hover,
  &:focus {
    outline: none;
    border-color: colors.$gray05;
  }

  & > img {
    width: 100%;
    height: 100%;
  }
}

.threeXL {
  @include typography.three-xl-regular;
}

.fourXL {
  @include typography.four-xl-regular;
}
