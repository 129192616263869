@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

.root {
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
}

.content {
  width: 37rem;
  background-color: colors.$gray80;
  border-radius: 0 1rem 1rem 0;
}

.logoWrapper {
  background-color: colors.$gray20;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  svg {
    height: 2rem;
  }
  border-radius: 1rem 0 0 1rem;
}

.textContainer {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}

.title {
  @include typography.s-bold;
  margin: 0 0 0.25rem 0;
}

.text {
  @include typography.s-regular;
  margin: 0;
}
