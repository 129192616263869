@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

.tabContainer {
  display: flex;
  flex-direction: row;
  margin-top: 2.667rem;
  border-bottom: 2px solid colors.$whiteOpacity40;
  width: 90%;
  opacity: 1;

  &:focus-within,
  &:hover {
    opacity: 1;
  }
  @supports selector(:focus-within) {
    opacity: 0.8;
  }
}

.tab {
  display: flex;
  padding: 0.458rem 0 0.817rem 0;
  margin-right: 1.667rem;

  @include typography.s-bold;
  opacity: inherit;
  border-bottom: 0.15rem solid transparent;

  :global(.hover-styles-enabled) &:hover,
  &:focus {
    padding-bottom: 0.667rem;
    border-bottom: 0.3rem solid colors.$gray05;
    outline: 0;
  }
}

.activeTab {
  border-bottom-color: colors.$gray05;
}
