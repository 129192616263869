@use 'src/client/styles/colors';

.backgroundWrapper {
  width: 100%;
  height: 100%;

  background: radial-gradient(
    92.55% 92.55% at 51.46% -12.27%,
    rgba(41, 204, 222, 0.6) 0%,
    rgba(20, 21, 22, 0.6) 100%
  );
}

.logo {
  position: absolute;
  top: 3.5rem;
  right: 6rem;
  height: 1.667rem;
  margin: 0.333rem;
  color: colors.$gray05;
}
