@use '../styles/colors.scss';
@use './list-styles/base-card';
@use './list-styles/base-list';
@use './list-styles/card-focus-styles';
@use './list-styles/variables';


// This file defines styles for all supported list presentations by composing presentation-specific
// further definitions from nested partials onto a shared base style.
//
// A list has an API-defined 'presentation'- or 'style'-property, that defines layout (*-strip / *-grid) and a card
// visualization (cover card, poster strip card, large strip card, ...). The API definitions are mapped to supported
// implementations clientside, so faulty definitions or technically valid but less used combinations are ignored. While
// lists also define some other UI elements by various exceptions (eg. hero, promotions, ...), these are handled in
// separate components. Therefore a list (layout) and the nested card styles are defined as one entity, as cards are
// rendered identically (at DOM level) regardless of the list presentations. A card that is rendered as a card does not
// exist outside of a list.
//
// Defaults given apply completely as is to large-strips, and medium-grid cards are identical to large-strip cards,
// whereas the other presentations have further overrides and other tweaks based on individual card designs.
//
// large strips do not have their own separate partial files, since they are covered by the defaults

:global(.cover-strip),
:global(.large-strip),
:global(.medium-grid),
:global(.poster-strip),
:global(.square-card-strip) {
  @include base-list.common;
}

:global(.large-strip),
:global(.medium-grid),
:global(.square-card-strip) {
  @include base-card.common;
}

@import './list-styles/cover-strip',
  './list-styles/medium-grid', './list-styles/poster-strip',
  './list-styles/square-card-strip';

:global(.hover-styles-enabled) :global(.large-strip),
:global(.hover-styles-enabled) :global(.medium-grid),
:global(.hover-styles-enabled) :global(.poster-strip),
:global(.hover-styles-enabled) :global(.square-card-strip) {
  .card:hover {
    @include card-focus-styles.default;
  }
}

:global(.hover-styles-enabled) :global(.cover-strip) {
  .card:hover {
    @include card-focus-styles.coverstrip;
  }
}

:global(.cover-strip),
:global(.large-strip),
:global(.poster-strip),
:global(.square-card-strip) {
  .list.hasElementsBeforeFocused {
    .card {
      transform: translateX(-100%);
    }
  }
}

:global(.lasten) {
  :global(.large-strip),
  :global(.medium-grid) {
    .card .content .progressbarWrapper {
      .progressbar {
        background-color: colors.$yleDarkYellow;
      }
    }
  }
}
