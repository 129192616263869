@use 'src/client/View/variables';
@use 'src/client/styles/colors';
@use 'src/client/styles/typography';


$readMoreFi: 'näytä lisää';
$readMoreSv: 'visa tilläggsuppgifter';
$spaceAroundPlayCardColumn: 0.666rem;

.itemView {
  @include typography.m-regular;
  width: auto;
  height: 100vh;
  padding-left: calc(variables.$sidebarWidth + 1.1rem);
  box-sizing: border-box;
  color: colors.$gray05;
  padding-top: 3.875rem;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;

  object {
    height: 100%;
    opacity: 1;
  }

  object:not([data]) {
    opacity: 0;
  }

  .gradient {
    position: absolute;
    top: 0;
    right: 0;
    width: 100.5%;
    height: 100.5%;
    background-image: linear-gradient(
      90deg,
      colors.$gray95,
      transparent
    );
  }
}

.detailsWrapper {
  position: relative;
  max-width: 36rem;
  height: 24.1rem;

  .complementaryTitle {
    @include typography.m-bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 0.5rem;
    height: 1.85rem;
  }

  .contentWrapper {
    height: 21.7rem;
  }

  .logoWrapper {
    position: relative;
    height: 7.667rem;
    margin-bottom: 0.667rem;
    img {
      position: absolute;
      bottom: 0;
    }
  }

  .title {
    @include typography.three-xl-bold;
    margin-top: 0;
    margin-bottom: 0.667rem;
    max-height: 2.5em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .compactTitle {
    @include typography.m-bold;
  }

  .labelsWrapper {
    @include typography.s-regular;
    line-height: 1.8rem;
    margin-bottom: 0.667rem;
    max-height: 3.75rem;
    overflow: hidden;
  }

  .genericLabel {
    display: inline-block;
    opacity: 0.5;

    &:not(:first-of-type)::before {
      margin-left: 0.5em;
      margin-right: 0.5em;
      font-weight: bold;
      content: '\00B7';
    }
  }

  .descriptionWrapper {
    position: relative;
    left: -0.666rem;
    border: 2px solid transparent;
    padding: 0.3rem 0.5rem;
    outline: 0;

    :global(.hover-styles-enabled) &:hover,
    &:focus {
      border: 2px solid #f8f9fa;
      &::after {
        display: block;
        position: absolute;
        padding-top: 1rem;
        font-weight: 700;
        font-size: 0.83rem;
        text-transform: uppercase;
        content: quote($readMoreFi);
      }
      &.isSwedish::after {
        content: quote($readMoreSv);
      }
    }
  }
  .descriptionText {
    line-height: 1.4;
    margin: 0;
    max-height: 6.53rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  
    &.compact {
      -webkit-line-clamp: 3;
    }
  }
}

.playCardWrapper {
  display: flex;
  margin: -$spaceAroundPlayCardColumn;
  align-items: center;
  position: relative;
  margin-bottom: 0;

  .column {
    flex-shrink: 0;
    padding: $spaceAroundPlayCardColumn;

    .title {
      margin: 0;
      font-weight: bold;
    }

    .label {
      font-size: 1rem;
    }

    object {
      padding-right: 0.5rem;
    }

    .viewStyles.buttonPrimary.geoblocked {
      &:focus,
      &:hover {
        .geoblockedIcon {
          filter: brightness(0.01);
        }
      }

      .geoblockedIcon {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.7rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        background-image: url('https://images.cdn.yle.fi/image/upload/icon-georestricted-tw.svg.svg');
      }

      & svg {
        display: none;
      }
    }
  }
}

.actionsWrapper {
  position: relative;
  max-width: fit-content;

  a {
    text-decoration: none;
  }

  .action {
    margin-top: 0.205rem;
    margin-bottom: 0.44rem;
    max-width: 25.1rem;
    @include typography.m-regular;
    text-decoration: none;
    border-radius: 0.5rem;
    padding: 0.5rem 0.9rem;
    cursor: pointer;
    outline: none;

    color: colors.$gray05;
    background: transparent;

    img,
    label {
      vertical-align: middle;
      margin-right: 0.56rem;
    }
    span {
      height: 1.8rem;
      margin-right: 0.3rem;
    }

    & svg {
      path {
        fill: colors.$gray05;
      }
    }

    .iconWrapper {
      display: inline-block;
      width: 3rem;
      margin-right: 1rem;
      text-align: center;
      & svg {
        max-height: 2rem;
        max-width: 1.9rem;
        vertical-align: middle;
      }
    }
  }

  a:focus
    .action,
    .action:focus,
    :global(.hover-styles-enabled)
    &
    .action:hover {
    color: colors.$gray95;
    background: colors.$gray05;

    & svg {
      path {
        fill: colors.$gray95;
      }
    }
  }
}

.programInfoWrapper {
  position: absolute;
  top: 0;
  left: -5.375rem;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  object {
    position: absolute;
  }
}
