@use '../styles/colors';
@use '../styles/typography';
@use '../View/variables';

$FULL_HD_WIDTH: 1920px;
$HD_WIDTH: 1280px;

$FULL_HD_HEIGHT: 1080px;
$HD_HEIGHT: 720px;

.testpage {
  height: 100%;
  padding-left: variables.$sidebarWidth; 
  overflow: hidden;

  .limelight {
    position: relative;
    top: 10%;
    height: 15rem;
    margin-left: 2rem;
    width: 92%;
    color: colors.$gray05;
  }

  .limelightTitle {
    @include typography.three-xl-bold;
    max-height: 2.56em;
    overflow: hidden;
  }

  .limelightDescription {
    @include typography.m-regular;
    margin-top: 0.5rem;
    max-width: 90%;
    max-height: 6.36rem;
    overflow-y: hidden;
  }

  .playerType {
    color: colors.$gray05;
    margin-left: 2rem;
    font-size: 1.5rem;

    span {
      display: inline-block;
      padding-right: 2rem;
      opacity: 0.5;  
    } 
    .selected {
      opacity: 1;
    }
  }
}

.playerTypeMarker {
  position: absolute;
  left: 0;
  bottom: 9%;
  background-color: colors.$gray05;
  color: colors.$gray95;
  font-weight: 700;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.testScrollPoint {
  position: relative;
}

.testStrip {
  position: relative;
  white-space: nowrap;
  padding-top: 1.5rem;
  min-height: 13.4375rem;
  margin-left: 2rem;

  & .title {
    color: colors.$gray05;
    font-size: 1.5rem;
    margin-left: 0.5rem;
    font-weight: 700;
  }
  
  & .listContainer {
    overflow: hidden;
    width: 1100px;
  }

  &.fullhd .listContainer {
    width: 1650px;
  }
  
  & .testCard {
    position: relative;
    width: $HD_WIDTH;
    height: $HD_HEIGHT;
    display: inline-block;
    border: 0.3125rem solid transparent;

  
    &:focus,
    .hover-styles-enabled &:hover {
      border-color: colors.$gray05;
    }
  }

  &.fullhd .testCard {
    width: $FULL_HD_WIDTH;
    height: $FULL_HD_HEIGHT;
  }
  
  & .testCardTitle {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: colors.$blackOpacity70;
    max-height: 2.8em;
    padding: 0.65rem 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.4em;
    color: colors.$gray05;
  }
  
  & img {
    width: 100%;
    height: 100%;
  }
}
