@use 'src/client/styles/typography';

.seriesTitle {
  @include typography.xl-regular;

  line-height: 3.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3.3rem;
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  margin: 0;
}

.episodeTitle {
  @include typography.m-regular;

  min-height: 1.36rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.descriptions {
  display: inline-block;
  width: 90%;
  height: 5.6rem;
}

.headerLeftElements {
  width: 70%;
  display: inline-block;
  text-align: left;
  vertical-align: top;

  :global(.lasten) & :global(.backButtonWrapper) {
    position: static;
    margin-left: 0;
  }
}

.headerContainer {
  width: 100%;
  padding: 3.5rem 0 1.5rem 0;
  position: relative;
  z-index: 99;
  min-height: 4.125rem;
  transition: transform 0.4s;
  opacity: 0;
  pointer-events: none;
}

.visible {
  opacity: 1;
}
