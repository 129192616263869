@use '../styles/colors';
@use '../styles/typography';

.backButton {
  margin-right: 0.5rem;
  height: 3rem;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: inherit;
  color: inherit;
  font: inherit;
  outline: none;
}

.backButtonWrapper {
  font-size: 1.5rem;
  color: colors.$gray05;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  margin-top: 0.41rem;
  display: inline-block;
  pointer-events: auto;
  vertical-align: top;

  :global(.lasten) &.player {
    position: static;
    margin-left: 0;
  }
}


.arrowWrapper {
  display: block;
  width: 1.8em;
  height: 1.8em;
  border-radius: 50%;
  border: 0.13em solid transparent;

  .backButtonWrapper:focus &,
  :global(.hover-styles-enabled) .backButtonWrapper:hover & {
    background: transparent;
    border-color: currentColor;

    svg {
      path {
        fill: currentColor;
      }
    }
  }

  .backButtonWrapper:focus  &.playerFocus,
  :global(.hover-styles-enabled) .backButtonWrapper:hover &.playerFocus {
    background: currentColor;

    svg {
      path {
        fill: colors.$gray95;
      }
    }
  }
}

.text {
  vertical-align: top;
  display: inline-block;
  line-height: 3rem;
}
