@use '../../styles/colors.scss';

.liveMarker {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.075rem 0.1rem;
  margin-left: 2.4rem;
  border-radius: 4px;
  height: 1rem;
  width: 2rem;
  font-size: 0.7rem;
  font-weight: 700;
  color: colors.$white;
  background-color: colors.$red50;
}

.dvrRewound {
  color: colors.$black;
  background-color: colors.$gray05;
}
