@use '../../styles/colors';
.backToLive {
  position: absolute;
  bottom: 28vh;
  right: 5.8rem;
}

.button {
  padding: 0.583rem 1.666rem;
  border: 0;
  outline: 0;
  font-size: 1.333rem;
  font-weight: 700;
  line-height: 1.815rem;
  display: inline-block;
  border-radius: 1.5rem;
  cursor: pointer;
  text-decoration: none;
  background: colors.$whiteOpacity20;
  color: colors.$gray05;

  &:focus,
  :global(.hover-styles-enabled) &:hover {
    background: colors.$gray05;
    color: colors.$gray95;

    path {
      fill: colors.$gray95;
    }
  }
}
