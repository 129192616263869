@use '../../styles/colors.scss';

// Every other content except for the label-wrapper should be ~0.66rem away from the card edges. Label wrapper should be
// ~0.33rem away from the edges.
$contentPadding: 0.33rem;

:global(.poster-strip) {
  .card {
    width: calc(15.875rem * (9 / 13));
    height: 15.875rem;

    &.centeredText {
      .content {
        padding: 0;
        justify-content: center;
        align-items: center;
      }

      .labelWrapper,
      .progressbarWrapper {
        display: none;
      }
    }

    .content {
      padding: $contentPadding;

      .primaryText {
        color: colors.$white;
        margin-bottom: 0px;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.4em;
        max-height: 3.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: normal;
        overflow-wrap: break-word;
        text-align: center;
      }
    }

    .labelWrapper {
      overflow: hidden;
      margin-bottom: auto;

      .label {
        display: inline-block;
        background: colors.$white;
        color: colors.$black;
        font-size: 0.833rem;
        text-transform: uppercase;
        margin-right: 0.2rem;
        padding: 0.2rem 0.666rem;
        border-radius: 0.333rem;
      }
    }

    .secondaryText {
      display: none;
    }
  }
}
