@use './list-styles/variables';
@use '../styles/colors';
@use '../styles/typography';
@use './variables' as viewVariables;

.backgroundContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.backgroundImage {
  left: 1px;
  top: -1px;
  position: relative;
  width: 100%;
  opacity: 1;
  transition: opacity variables.$transitionTime linear;

  &:not([data]) {
    opacity: 0;
  }

  &Blurred {
    opacity: 0.6;
  }
}

.foregroundImage {
  opacity: 1;
  transition: opacity variables.$transitionTime linear;
  position: absolute;
  top: 3.75rem;
  right: 3.95rem;

  &Hidden {
    display: none;
  }

  &:not([data]) {
    opacity: 0;
  }
}

.gradientHorizontal,
.gradientVertical {
  color: colors.$gray95;
  width: 100.5%;
  height: 100.5%;
  position: absolute;
  top: 0;
}

.gradientHorizontal {
  background: linear-gradient(0deg, currentcolor 1.44%, rgba(19, 20, 21, 0) 13.7%);

  &.wide {
    background: linear-gradient(0deg, currentcolor 4.22%, rgba(19, 20, 21, 0) 37.44%);
  }

  &.narrow {
    background: linear-gradient(0deg, currentcolor 2%, rgba(0, 0, 0, 0) 30%);
  }
}

.gradientVertical {
  &.wide {
    background: linear-gradient(90deg, currentcolor 0%, rgba(19, 20, 21, 0) 69.68%);
  }

  &.narrow {
    background: linear-gradient(90deg, currentcolor 0%, rgba(19, 20, 21, 0.00) 80%);
  }
}

.limelight {
  height: 20rem;
  color: colors.$gray05;
  display: flex;
  position: relative;
  flex-direction: column;
  padding-top: 8.3vh;

  &.onlyTitle {
    height: 8rem;
  }

  &.withHero {
    height: 27.5rem;
    padding-top: 3.75rem;
    padding-bottom: 9vh;

    .areenaLogo {
      display: block;
    }

    .heroButtonContainer {
      bottom: 0;
      display: block;
    }

    .limelightLogoWrapper {
      position: relative;
      height: 7.667rem;
      margin-bottom: 2.125rem;
    
      object {
        position: absolute;
        bottom: 0;
      }
    }

    .limelightTitle {
      @include typography.four-xl-bold;
      line-height: 1.25;

      &.titleSmall {
        @include typography.xl-bold;
      }
    }

    .limelightDescription {
      @include typography.m-regular;
    }
  }

  .isVisible {
    display: none;
  }  
}

:global(.lasten) .limelight {
  padding-bottom: unset;

  &:not(.withHero) {
    height: 6rem;
  }

  .backgroundContainer {
    width: 100%;
    height: 100%;
  }
}

:global(.lasten) :global(.withTabs) .limelight {
  height: 7rem;
}

.labelHighlight {
  display: inline-block;
  border-radius: 0.333rem;
  padding: 0.166rem 0.666rem;
  margin-right: 0.2rem;
  margin-bottom: 0.667rem;
  color: colors.$gray95;
  background-color: colors.$gray05;

  @include typography.xs-regular;
}

.content {
  z-index: 0;
  position: absolute;
  margin-left: 1rem;
  bottom: 0;
  margin-bottom: 5rem;
  width: 50%;

  > * {
    &:empty:not(object) {
      display: none;
    }
  }
  &.similar {
    top: 5rem;
  }
}

:global(.lasten) .content {
  $sidebarWidth: viewVariables.$sidebarWidth;
  margin-left: 1rem;
}

.areenaLogo {
  display: none;
  height: 2.3125rem;
  width: 11.7rem;
  position: absolute;
  top: 4rem;
  right: 6rem;
}

.heroButtonContainer {
  display: none;
  padding-top: 1.58rem;

  > *:not(:last-child) {
    margin-right: 1.33rem;
  }
}

.limelightLogoWrapper {
  position: relative;
  height: 5rem;
  margin-bottom: 0.667rem;

  object {
    position: absolute;
    bottom: 0;
  }
}

.limelightTitle {
  @include typography.three-xl-bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  margin-bottom: 0.667rem;

  &.titleSmall {
    @include typography.m-bold;
  }
}

.limelightDescription {
  @include typography.m-regular;
  max-height: 5.4rem;
  overflow-y: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-transform: none;

  &.limelightDescriptionShort {
    -webkit-line-clamp: 3;
  }
}

.labelBroadcastContainer {
  width: 100%;
  display: inline-flex;
  margin-top: 0.2rem;
  margin-bottom: 0.667rem;
  padding: 0.2rem 0;
  color: colors.$gray50;
}

.labelBroadcast {
  @include typography.s-regular;

  &:not(:last-child) {
    margin-right: 0.667rem;

    &:after {
      content: '\00B7';
      font-weight: bold;
      padding-left: 0.667rem;
    }
  }
}

:global(.lasten) .backButtonWrapper {
  position: absolute;
  top: 3rem;

  $sidebarWidth: viewVariables.$sidebarWidth;
  margin-left: 1rem;
  left: 0;
}

.viewTitle {
  @include typography.m-bold;
  overflow: hidden;
  white-space: nowrap;
  position: fixed;
  top: 2.917rem;
}

.lastenLogoContainer {
  position: absolute;
  top: 3rem;
  right: 5.75rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.75rem;
  font-weight: 700;

  object {
    border-radius: 50%;
    margin-left: 1.25rem;
  }
}
