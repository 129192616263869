@use '../styles/colors';

.routes {
  position: absolute;
  background: colors.$gray95;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
