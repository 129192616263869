@use '../../styles/colors.scss';
@use './card-focus-styles';
@use './variables';

:global(.cover-strip) {
  .title {
    display: none;
  }

  a:focus,
  .card:focus {
    border: none;
    @include card-focus-styles.coverstrip;
  }

  .card {
    flex-direction: column;
    border: none !important;
    outline: none;

    .labelWrapper, .secondaryText {
      display: none;
    }

    & :global(.loadingIndicator) {
      position: relative;
      border: 0.333rem solid transparent;
      width: 9rem;
      height: 9rem;
      border-radius: 50%;
      background-clip: content-box;

      :global(.loadingAnimation) {
        border-radius: 50%;
      }
    }

    .content {
      position: static;
      justify-content: center;
      align-items: center;

      .primaryText {
        font-weight: 700;
        text-align: center;
        color: colors.$gray05;
        font-size: 1rem;
        text-transform: uppercase;
        line-height: 1.4rem;
        max-width: 9rem;
        margin: 0.4rem auto 0 auto;
        white-space: normal;
        max-height: 2.8rem;
        overflow: hidden;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
  }

  object {
    position: relative;
    border: 0.333rem solid transparent;
    border-radius: 50%;
    width: 9rem;
    height: 9rem;
    background: colors.$placeholderBg;
    background-clip: content-box;
    opacity: 1;
    transition: opacity variables.$transitionTime linear;
  }

  object:not([data]) {
    opacity: 0.8;
  }
}

:global(.lasten) :global(.cover-strip) .card .content {
  .primaryText {
    color: colors.$white;
    font-size: 0.8rem;
    line-height: 1rem;
  }
}
