@use 'src/client/styles/typography';

.container {
  display: flex;
  padding-top: 2.33rem;
  flex-direction: row;
  justify-content: space-between;
}

.textContent {
  @include typography.l-regular;
  width: 32.75rem;
  margin-right: 6.38rem;
  p {
    margin-bottom: 1.5rem;
  }
}

.image {
  height: 23.1rem;
}
