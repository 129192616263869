.subtitleTransparent {
  text-shadow:
    -1px -1px 1px #000,
    1px -1px 1px #000,
    -1px 1px 1px #000,
    1px 1px 1px #000;
  background: transparent;
  color: rgba(248, 249, 250, 1);
}

.subtitleBlack {
  background: rgba(0, 0, 0, 0.6);
  color: rgba(248, 249, 250, 1);
}

.subtitleWhite {
  background: rgba(255, 255, 255, 0.75);
  color: rgba(19, 20, 21, 1);
}

.subtitleSmall {
  font-size: 1.9583rem;
  line-height: 2.9375rem;
}

.subtitleNormal {
  font-size: 2.625rem;
  line-height: 3.9375rem;
}

.subtitleLarge {
  font-size: 3.9166rem;
  line-height: 5.875rem;
}
