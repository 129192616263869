@use '../styles/colors.scss';
@use 'src/client/styles/typography';

.root {
    margin-left: -5.375rem;
    height: 100%;
    display: flex;
    justify-content: center;
}

.viewContainer {
    color: colors.$white;
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 100%;
    justify-content: center;
}

.messageContent {
    display: flex;
}

.iconHD {
 width: 266px;
 height: 266px;
}

.iconFullHD {
    width: 400px;
    height: 400px;
}

.textContainer {
 flex: 2;
 justify-content: center;
 margin-left: 1rem;
}

.title {
    @include typography.xl-bold;
    margin: 2.375rem 0 0;
}

.description {
    margin: 1.666rem 0 0;
}

.buttonContainer {
    margin: 3.333rem 0 0;
    display: flex;
    justify-content: center;
}
