$weight-regular: 400;
$weight-bold: 700;
$weight-extra-bold: 800;

@mixin four-xl-regular {
  font-size: 3rem;
  font-weight: $weight-regular;
  line-height: 3.667rem;
}

@mixin four-xl-bold {
  font-size: 3rem;
  font-weight: $weight-bold;
  line-height: 3.667rem;
}

@mixin three-xl-regular {
  font-size: 2.33rem;
  font-weight: $weight-regular;
  line-height: 3rem;
}

@mixin three-xl-bold {
  font-size: 2.33rem;
  font-weight: $weight-bold;
  line-height: 3rem;
}

@mixin two-xl-regular {
  font-size: 2rem;
  font-weight: $weight-regular;
  line-height: 2.667rem;
}

@mixin two-xl-bold {
  font-size: 2rem;
  font-weight: $weight-bold;
  line-height: 2.667rem;
}

@mixin xl-regular {
  font-size: 1.667rem;
  font-weight: $weight-regular;
  line-height: 2.27rem;
}

@mixin xl-bold {
  font-size: 1.667rem;
  font-weight: $weight-bold;
  line-height: 2.27rem;
}

@mixin l-regular {
  font-size: 1.33rem;
  font-weight: $weight-regular;
  line-height: 1.83rem;
}

@mixin l-bold {
  font-size: 1.33rem;
  font-weight: $weight-bold;
  line-height: 1.83rem;
}

@mixin m-regular {
  font-size: 1.167rem;
  font-weight: $weight-regular;
  line-height: 1.59rem;
}

@mixin m-bold {
  font-size: 1.167rem;
  font-weight: $weight-bold;
  line-height: 1.59rem;
}

@mixin s-regular {
  font-size: 1rem;
  font-weight: $weight-regular;
  line-height: 1.33rem;
}

@mixin s-bold {
  font-size: 1rem;
  font-weight: $weight-bold;
  line-height: 1.33rem;
}

@mixin xs-regular {
  font-size: 0.833rem;
  font-weight: $weight-regular;
  line-height: 1.083rem;
}

@mixin xs-bold {
  font-size: 0.833rem;
  font-weight: $weight-bold;
  line-height: 1.083rem;
}
