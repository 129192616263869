@use '../../styles/colors.scss';
@use '../../styles/typography.scss';

.videoContainer {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-weight: 400;

  object,
  video,
  audio {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
  }

  video::cue {
    font-family: Tiresias, TiresiasScreenfont, sans-serif;
  }

  &.Subtitle-background-transparent video::cue {
    text-shadow:
      -1px -1px 1px colors.$black,
      1px -1px 1px colors.$black,
      -1px 1px 1px colors.$black,
      1px 1px 1px colors.$black;
    background: transparent;
    color: rgba(248, 249, 250, 1);
  }

  &.Subtitle-background-black video::cue {
    background: colors.$blackOpacity60;
    color: rgba(248, 249, 250, 1);
  }

  &.Subtitle-background-white video::cue {
    background: rgba(255, 255, 255, 0.75);
    color: rgba(19, 20, 21, 1);
  }

  &.Subtitle-size-small video::cue {
    font-size: 1.9583rem;
    line-height: 2.9375rem;
  }

  &.Subtitle-size-normal video::cue {
    font-size: 2.625rem;
    line-height: 3.9375rem;
  }

  &.Subtitle-size-large video::cue {
    font-size: 3.9166rem;
    line-height: 5.875rem;
  }
}
