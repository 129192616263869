.preview {
  border-radius: 0.5rem;
  height: 9.6875rem;
  position: relative;
  margin-top: 4.458rem;
  font-weight: 700;
}

.previewWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 66%;
  text-align: center;
}

.previewText {
  text-align: center;
  margin: 0;
  font-size: 1.333rem;
  font-weight: 700;
}

.small {
  font-size: 1.9583rem;
}

.normal {
  font-size: 2.625rem;
}

.large {
  font-size: 3.9166rem;
}

.black {
  background: rgba(0, 0, 0, 0.6);
  color: rgba(248, 249, 250, 1);
}

.white {
  background: rgba(255, 255, 255, 0.75);
  color: rgba(19, 20, 21, 1);
}

.transparent {
  text-shadow:
    -1px -1px 1px #000,
    1px -1px 1px #000,
    -1px 1px 1px #000,
    1px 1px 1px #000;
}
