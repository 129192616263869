@use 'src/client/styles/typography';
@use 'src/client/styles/colors';

@mixin carouselSlide($step) {
  left: calc(#{-$step * 100}vw + $step * 2.5rem);
}

.landingHeader {
  @include typography.four-xl-bold;
  margin: 0;
  width: 36.9375rem;
}

.landingText {
  @include typography.xl-regular;
}

.carouselSlide {
  position: relative;
  background-color: transparent;
  border-radius: 1rem;
  border: 0.125rem solid transparent;
  width: 75rem;
  min-height: 24.833rem;
  margin: 5.666rem 2.5rem 0;
  overflow: hidden;
}

.viewport {
  position: absolute;
  display: flex;
  overflow-x: hidden;
  @include carouselSlide(0);
  transition: left 0.5s linear;
  height: 100%;
}

.viewportSlide1 {
  @include carouselSlide(1);
}

.viewportSlide2 {
  @include carouselSlide(2);
}

.viewportSlide3 {
  @include carouselSlide(3);
}

.carouselSlide:focus,
.carouselSlide:hover {
  border: 0.125rem solid colors.$white;
  outline: none;
}

.carouselSlide:focus-visible {
  outline: none;
}

@mixin carouselBaseContent {
  display: flex;
  height: 100%;
  margin-right: 2.5rem;
  width: 75rem;
}

.carouselLandingContent {
  @include carouselBaseContent;
  display: flex;
  flex-direction: column;
  gap: 1.666rem;
  margin: 8rem 0 0 4.41rem;
}

.carouselContent {
  @include carouselBaseContent;
}

.contentWrapper {
  display: flex;
  align-items: center;
  margin: 3.666rem 7.833rem 4.5rem 7.833rem;
}

.carouselImage {
  width: 16.666rem;
  height: 16.666rem;
}

.carouselText {
  display: flex;
  flex-direction: column;
  margin: 3.083rem 0;
  width: 41rem;

  h1 {
    @include typography.two-xl-bold;
    margin-bottom: 1.666rem;
    color: colors.$gray05;
  }

  p {
    @include typography.xl-regular;
    color: colors.$gray05;
    margin: 0;
  }
}

.indicators {
  position: absolute;
  display: flex;
  bottom: 1.2916rem;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
}

.indicator {
  background-color: colors.$gray50;
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  margin: 0.333rem;
  border-style: solid;
  border-color: transparent;
  border-width: 0px;
  transition:
    background-color 0.3s ease,
    border-color 0.6s ease;
}

.activeIndicator {
  background-color: colors.$gray05;

  width: 0.666rem;
  height: 0.666rem;
  border-style: solid;
  border-color: colors.$gray05;
  border-width: 0.334rem;
  transition:
    background-color 0.3s ease,
    border-color 0.6s ease;
}
