@use 'src/client/View/variables';
@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

$textDefault: colors.$white;

.root {
  color: $textDefault;
  width: 100%;
  height: 100%;
  margin-left: 1rem;
  padding-left: variables.$sidebarWidth;

  p {
    margin: 0.5rem 0;
  }
}

.title {
  @include typography.xl-bold;
  line-height: 1.5;
  margin-top: 3.75rem;
}

.content {
  display: flex;
  margin-top: 2.33rem;
}

.background {
  position: absolute;
}
