@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.content {
  position: absolute;
  top: 11.8rem;
  width: 100%;
  height: 100%;
  text-align: center;
}

.routeName {
  @include typography.xl-bold;
  position: absolute;
  top: 5rem;
  left: 8rem;
  color: colors.$white;
}

.title {
  @include typography.three-xl-bold;
  color: colors.$white;
  margin-bottom: 2rem;
  width: 36rem;
  display: inline-block;
}

.description {
  @include typography.two-xl-regular;
  color: colors.$white;
  width: 41rem;
  margin-bottom: 4.5rem;
  display: inline-block;
}
