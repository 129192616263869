@use 'src/client/styles/typography';
@use 'src/client/styles/colors';

.simulatedInput {
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 1rem;
  font-family: inherit;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  height: 2.8rem;
  width: 100%;
  border-color: transparent;
  border-width: 0.125rem;
  border-style: solid;
  background-color: colors.$whiteOpacity20;
  caret-color: transparent;

  .inputContentWithCursor,
  .inputPlaceholder {
    position: absolute;
    display: block;
    padding-top: 0.2rem;
    padding-left: 0;
    padding-right: 0;
    height: 1.6rem;
    line-height: 1.7rem;
    margin: 0.125rem 0.4rem;
    font-size: 1rem;
    font-weight: normal;
    white-space: pre;
    overflow: hidden;
  }

  .inputPlaceholder {
    top: 0.65rem;
    color: grey;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }

  &.activeInput {
    border-color: colors.$gray05;

    .inputContentWithCursor {
      color: colors.$gray05;
      border-right: 0.125rem solid colors.$gray05;
      animation: blinkingCursor 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28)
        infinite;
    }
  }

  &:focus {
    outline: none;
  }

  :global(.hover-styles-enabled) &:hover,
  &:focus {
    background: colors.$gray05;
    color: colors.$gray95;

    .inputContentWithCursor {
      color: colors.$gray95;
      border-color: colors.$gray95;
    }
  }
}

.inputContentWithCursor {
  top: 2.2rem;

  @keyframes blinkingCursor {
    50% {
      border-right: 0.125rem solid transparent;
    }
  }
}

:global(.lasten) .simulatedInput {
  background-color: colors.$white;
  color: colors.$gray95;
  border-color: colors.$gray05;
  border-radius: 0.3rem;

  span {
    text-transform: uppercase;
    font-weight: bold;
  }

  .inputPlaceholder {
    font-weight: bold;
  }

  .activeInput {
    border-color: colors.$gray05;
  }

  .inputContentWithCursor {
    color: colors.$gray95;
    border-right: 0.125rem solid colors.$gray95;
  }
}
