@use '../styles/colors';
@use './variables';

.sidebarBack {
  color: colors.$gray05;
  display: block;
  height: 100%;
  padding-left: 1.5rem;
  opacity: variables.$itemOpacityInitial;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 3.75rem;
    width: 3.75rem;
  }
}

:global(.hover-styles-enabled) .sidebarBack:hover, .sidebarBack:focus {
  opacity: 1;
  background-color: colors.$gray80;
}