@use '../../styles/colors.scss';
@use './variables';

// card content is rendered identically in large-strips, medium-grids and square-card-strips, so this file targets these
// presentations.

@mixin blocked {
  pointer-events: none;
  filter: grayscale(1);

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    content: '';
    background: colors.$blackOpacity60 center center no-repeat;
    opacity: 0.8;
  }
}

// Every other content except for the label-wrapper should be ~0.66rem away from the card edges. Label wrapper should be
// ~0.33rem away from the edges.
$contentPadding: 0.33rem;

@mixin common {
  .card {
    $cardHeight: variables.$stripCardHeight;

    width: calc($cardHeight * (16 / 9));
    height: $cardHeight;

    &.currently-playing .content {
      background-color: colors.$activeCardBackground;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-width: 1.5rem 0 1.5rem 2rem;
        border-color: transparent transparent transparent colors.$white;
        border-style: solid;
      }
    }

    &.centeredText {
      .content {
        padding-bottom: 0;
        justify-content: center;
        align-items: center;
      }

      .labelWrapper,
      .progressbarWrapper {
        display: none;
      }
    }

    .primaryText,
    .secondaryText,
    .progressbarWrapper {
      margin-left: $contentPadding;
      margin-right: $contentPadding;
    }

    &.hasProgress .content .progressbarWrapper {
      height: 0.1875rem;
      margin-top: 0.2rem;
      margin-bottom: $contentPadding;
    }

    &.blocked .content {
      @include blocked();
    }

    &.geoblocked .content {
      @include blocked();

      &::after {
        background-image: url('https://images.cdn.yle.fi/image/upload/icon-georestricted-tw.png.png');
      }
    }

    & :global(.loadingIndicator) {
      width: 16.625rem;
    }

    .content {
      justify-content: flex-end;
      box-sizing: border-box;
      padding: $contentPadding;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0) 50%
      );

      .labelWrapper {
        overflow: hidden;
        margin-bottom: auto;

        .label {
          display: inline-block;
          background: colors.$white;
          color: colors.$black;
          font-size: 0.833rem;
          text-transform: uppercase;
          margin-right: 0.2rem;
          padding: 0.2rem 0.666rem;
          border-radius: 0.333rem;
        }
      }

      .primaryText {
        position: relative;
        color: colors.$white;
        text-decoration: none;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.4em;
        max-height: 3.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: normal;
        overflow-wrap: break-word;
      }

      .primaryText.hasSecondaryText {
        -webkit-line-clamp: 1;
      }

      .secondaryText {
        color: colors.$white;
        position: relative;
        font-size: 1rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .progressbarWrapper {
        height: 0px;
        background-color: colors.$progressbarBackground;

        .progressbar {
          height: 100%;
          background-color: colors.$progressbarForeground;
        }

        .progressbar.live {
          background-color: colors.$progressbarForegroundLive;
        }
      }
    }
  }
}
