@use 'src/client/styles/colors';

$grey-05: #F8F9FA;
$grey-80: #292b2d;
$grey-95: #131415;

.keyboard {
  margin: 1rem 0.625rem 0.625rem 1rem;
  font-size: 1.17rem;
  font-weight: 700;
}

.keyboardButton {
  height: 2.375rem;
  width: 2.375rem;
  cursor: pointer;
  font: inherit;
  margin-right: 0.375rem;
  margin-bottom: 0.375rem;
  border: 0;
  padding: 0;
  white-space: pre;
  vertical-align: top;

  color: $grey-05;
  background-color: colors.$whiteOpacity20;

  &:hover, &:focus {
    color: $grey-95;
    background-color: $grey-05;
  }

  &[value='<empty>']:hover {
    background-color: $grey-80;
  }

  &:focus {
    outline: none;
  }

  & svg {
    height: 1rem;
    vertical-align: middle;
    position: relative;
    top: -2px;
  }

  :global(.hover-styles-enabled) &[value='<backspace>']:hover svg,
  &[value='<backspace>']:focus svg {
    path {
      fill: $grey-95;
    }
  }

  :global(.hover-styles-enabled) &[value='<enter>']:hover svg,
  &[value='<enter>']:focus svg {
    path {
      stroke: $grey-95;
    }
  }
}

:global(.lasten) .keyboardButton {
  border: 2px solid white;
  border-radius: 0.3rem;
  color: $grey-95;
  background-color: transparent;

  &:hover, &:focus {
    color: $grey-95;
    background-color: white;
  }

  &[value='<empty>']:hover {
    background-color: transparent;
  }

  & svg {
    path {
      fill: $grey-95;
    }
  }
}

.hidden {
  visibility: hidden;
}

.uppercase {
  text-transform: uppercase;
}
