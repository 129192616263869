@use 'src/client/styles/typography';
@use 'src/client/styles/colors';

.skipButtonsWrapper {
  position: absolute;
  right: 7.8rem;
  bottom: 10vh;

  &.doubleBottomOffset {
    bottom: 23vh;
  }

  &.tripleBottomOffset {
    bottom: 36vh;
  }
}

.skipButton {
  @include typography.l-bold;
  line-height: 1.815rem;

  padding: 0.583rem 1.666rem;
  border: 0;
  outline: 0;
  display: inline-block;
  border-radius: 1.5rem;
  cursor: pointer;
  text-decoration: none;
  background: colors.$skipButtonBackground;
  color: colors.$gray05;

  span {
    vertical-align: middle;
  }

  svg {
    display: inline-block;
    margin-right: 0.7rem;
    vertical-align: middle;
    height: 1.5rem;
  }

  path {
    fill: colors.$gray05;
  }

  &:focus,
  :global(.hover-styles-enabled) &:hover {
    background: colors.$gray05;
    color: colors.$gray95;

    path {
      fill: colors.$gray95;
    }
  }
}

:global(.lasten) .skipButton {
  color: colors.$white;

  &:focus,
  :global(.hover-styles-enabled) &:hover {
    background: colors.$white;
    color: colors.$black;

    path {
      fill: colors.$gray05;
    }
  }
}

.watchOutroButton {
  margin-right: 1.666rem;  
}
