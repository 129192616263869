@use 'src/client/View/variables';
@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

@mixin h1-styles {
  margin-top: 0;
  @include typography.three-xl-bold;
  margin-bottom: 2.5rem;
}

@mixin h2-styles {
  margin-top: 0;
  @include typography.xl-bold;
  margin-bottom: 0;
}

.focusedTabColors {
  background: colors.$gray05;
  color: colors.$gray95;
}

.selectedTabColors {
  background: colors.$gray95;
  color: colors.$gray05;
}

.settingsView {
  color: colors.$gray05;
  padding-left: variables.$sidebarWidth;
  box-sizing: border-box;
  height: 100%;
  width: 98%;
  background: colors.$gray95;

  .tabContainer {
    display: inline-block;
    width: 24%;
    height: inherit;
    font-size: 1.333rem;
    line-height: 1.815rem;
    background: colors.$gray90;

    h1,
    .title {
      margin: 3.833rem 0 3.916rem 1rem;
      font-size: 1.666rem;
      line-height: 1.588rem;
    }

    .tab {
      margin-left: 0.5rem;
      padding: 1rem 0.5rem;
      border-radius: 0.5rem 0 0 0.5rem;
      cursor: pointer;

      &.selected {
        background: colors.$gray95;
        color: colors.$gray05;
      }

      &:focus,
      :global(.hover-styles-enabled) &:hover {
        outline: 0;
        background: colors.$gray05;
        color: colors.$gray95;
      }
    }
  }

  .scrollBar {
    position: absolute;
    right: 2em;
    height: 85%;
    width: 1.2rem;
    border-radius: 0.625rem;
    background: colors.$gray95;
  }

  .scrollBarThumb {
    position: absolute;
    width: 1.2rem;
    border-radius: 0.625rem;
    background: rgba(26, 27, 28, 0.6);
  }

  .scrollBarThumb.focused {
    background: colors.$gray05;
  }

  .contentContainer {
    width: 76%;
    padding-left: 2.29rem;
    box-sizing: border-box;
    height: inherit;
    float: right;
    margin-top: 3em;
  }
}

.htmlContent {
  overflow-y: auto;
  scroll-behavior: smooth;
  outline: 0;
  height: 85%;
  padding-right: 2rem;

  &::-webkit-scrollbar {
    display: none;
  }

  div {
    margin-left: 0;
  }

  p,
  div {
    font-size: 1.333rem;
    line-height: 1.82rem;
    font-weight: 400;
    margin-right: 6rem;
    margin-top: 1rem;
  }
}

.data-protection {
  display: flex;
  flex-direction: column;
  height: 100%;

  img {
    width: 22.75rem;
    border-radius: 0.33rem;
  }

  h1 {
    @include h1-styles;
  }

  //data protection needs to be valid XHTML so target classname with global.
  :global(.logo-with-text) {
    display: flex;
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 1rem;

    p {
      padding-left: 2.29rem;
    }
  }
}

.consent-settings,
.contact-page,
.device-info {
  h1,
  h2 {
    @include h1-styles;
  }
}

.instructions {
  h1,
  h2:first-of-type {
    @include h1-styles;
  }
  h2,
  h3 {
    @include h2-styles;
  }
  h3:not(:first-of-type) {
    margin-top: 3.2rem;
  }
}
