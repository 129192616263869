@use '../../styles/colors.scss';

.playerControls {
  position: relative;
  margin-top: 0.75rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  text-align: center;
}

.controlsRight {
  position: absolute;
  top: 0;
  right: 0;
}
