@use '../styles/colors';

.scrollButton {
  position: fixed;
  width: 7.5em;
  height: 4.75em;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: colors.$gray95;
  font-size: 1rem;
  outline: none;
  transition: all 0.2s;
  -webkit-tap-highlight-color: transparent;
  pointer-events: all;

  &.left {
    left: 0;

    &.hidden {
      visibility: hidden;
      opacity: 0;
      transform: translateX(-100%);
    }
  }
  &.right {
    right: 0;

    &.hidden {
      visibility: hidden;
      opacity: 0;
      transform: translateX(100%);
    }

    .arrow {
      transform: rotate(180deg);
    }
  }
  &.up {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: 99;

    &.hidden {
      visibility: hidden;
      opacity: 0;
      transform: translate(-50%, -100%);
    }

    .arrow {
      transform: rotate(90deg);
    }
  }
  &.down {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);

    &.hidden {
      visibility: hidden;
      opacity: 0;
      transform: translate(-50%, 100%);
    }

    .arrow {
      transform: rotate(270deg);
    }
  }

  &:hover {
    .arrowWrapper .arrow {
      border-color: currentColor;
    }
  }

  &.down:active,
  &.up:active {
    transform: translateX(-50%) scale(0.9);
  }

  &.left:active,
  &.right:active {
    transform: scale(0.9);
  }

  .arrowWrapper {
    display: inline-block;
    background-color: colors.$gray05;
    border-radius: 50%;
    padding: 0.2rem;

    .arrow {
      display: block;
      border: 0.13em solid transparent;
      border-radius: 50%;
      width: 1.8em;
      height: 1.8em;
    }

    svg {
      path {
        fill: currentColor;
      }
    }
  }
}
