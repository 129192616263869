@use '../styles/colors';
@use '../styles/typography';

.content {
  position: absolute;
  top: 0%;
  left: 0%;
  color: colors.$gray95;
  display: flex;
  flex-direction: row;
  margin-top: 3.4rem;
}

.image {
  width: 36rem;
  height: 42rem;
  flex-shrink: 0;
}

.contentDetails {
  display: flex;
  width: 31rem;
  flex-direction: column;
  justify-content: center;
  margin-left: 4rem;
}

.title {
  margin-bottom: 2rem;
  @include typography.three-xl-bold;
}
.description {
  @include typography.two-xl-regular;
}

.buttons {
  margin-top: 3rem;
}

.button {
  display: inline-block;
  cursor: pointer;
  padding: 0.583rem 1.666rem;
  border: 0;
  outline: 0;
  margin: 0 0.5rem;
  @include typography.l-bold;
  border-radius: 1.5rem;
  color: colors.$gray95;
  background: colors.$blackOopacity09;
  font-family: inherit;

  &:focus,
  :global(.hover-styles-enabled) &:hover {
    color: colors.$gray05;
    background: colors.$gray95;
  }
}
