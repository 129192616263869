.content {
  margin-left: 5.375rem;
  padding: 2.5rem 1.888rem;
}

.popupHeading {
  font-size: 1.666rem;
  line-height: 1.81;
  font-weight: 700;
}
