@use '../styles/colors';
@use '../styles/typography';

.colLeft {
  @include typography.l-regular;
  width: 32.75rem;
  margin-right: 2rem;
}

.colRight {
  @include typography.l-regular;
  width: 32.75rem;
  p {
    margin-bottom: 1.67rem;
  }
}

.qrcode {
  width: 16.667rem;
  height: 16.667rem;
  background-color: colors.$white;
  margin-top: 3.333rem;
  border-radius: 0.666rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.textBold {
  @include typography.l-bold;
}

.infoBox {
  @include typography.xl-bold;
  margin-bottom: 1.67rem;
}

.url {
  letter-spacing: 0.1rem;
}

.token {
  letter-spacing: 1.666rem;
}
