@use '../../styles/colors.scss';

.button {
  cursor: pointer;
  display: inline-block;
  border: 0;
  border-radius: 50%;
  background-color: transparent;
  pointer-events: all;
  width: 4rem;
  height: 4rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  vertical-align: bottom;
  color: colors.$gray05;

  &:focus,
  :global(.hover-styles-enabled) &:hover {
    outline: 0;
    border: 0;
    background-color: colors.$gray05;
    color: colors.$gray95;
  }
}

:global(.lasten) .button {
  &:focus,
  :global(.hover-styles-enabled) &:hover {
    background-color: colors.$yleDarkYellow;
    color: colors.$gray05;
  }
}

.scaledDown {
  transform: scale(0.8);
}
