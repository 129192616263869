// medium-grid shares almost all styles with large-strip as the cards are identical,
// this just configures a grid layout.

:global(.medium-grid) {
  width: 100vw;
  max-width: 80rem;

  .list {
    flex-wrap: wrap;
  }
}

:global(.lasten) :global(.medium-grid) .title {
  display: none;
}
