.playerSeeking {
  position: relative;
  height: 9.5rem;
  width: 100%;
}

.wrapper {
  position: relative;
  text-align: center;
  width: 10.6rem;
}

.displayNone {
  display: none;
}
