.programImage {
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 3.84rem;
  margin: 0;
  transition: all 0.4s;
  width: 30rem;
  height: 30rem;
  transform: translateX(-50%);
}

.isPlayerUiVisible {
  top: 11rem;
  width: 22rem;
  height: 22rem;
}
