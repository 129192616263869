@use 'src/client/styles/colors';
@use 'src/client/styles/typography';

.root {
  display: none;
  position: absolute;

  $margin: 3.75rem;
  top: $margin;
  bottom: $margin;
  left: $margin;
  right: $margin;

  background-color: colors.$gray80;
  border-radius: 1rem;

  &.open {
    display: flex;
  }
}

.buttonList {
  margin: 1.667rem 0 0;
  padding: 0;
  list-style: none;

  display: flex;

  & > .buttonListItem {
    margin: 0;

    & ~ .buttonListItem {
      margin-left: 1rem;
    }
  }
}

.container {
  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 0 16rem;
}

.header {
  margin: 0;
  padding: 0;

  @include typography.xl-bold;
}

.description {
  margin: 0;
  padding: 1.667rem 0;
  text-align: center;

  @include typography.l-regular;
}
