.content {
  margin-left: 5.375rem;
  padding: 2.5rem 1.888rem;
}

.radioListWrapper {
  position: relative;
  top: 2rem;
  height: 34.826rem;
  overflow: hidden;
}

@mixin scrollButton {
  position: relative;

  button {
    position: absolute;
    left: 50%;
  }
}

.scrollButtonUp {
  @include scrollButton;
  top: -2rem;
}

.scrollButtonDown {
  @include scrollButton;
  bottom: -6rem;
}
