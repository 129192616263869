@use '../styles/colors';
@use '../styles/typography';

.cookieConsent {
  height: 100%;
  box-sizing: border-box;
  font-family: inherit;
  color: colors.$gray05;
}

.container {
  height: 100%;
  background: colors.$gray95;
}

.language {
  position: relative;
  padding-top: 2.03125rem;
  text-align: center;
}

.languageSelect {
  display: inline-block;
  position: relative;
}

.languageSelected {
  width: 11rem;
}

.languageSelectionDropdown {
  position: absolute;
  width: 11rem;
  background: colors.$gray95;
  top: 2.0625rem;
  left: 0;
}

.languageButtonDropdown {
  width: 100%;
  font-weight: typography.$weight-bold;
  padding-left: 1.69875rem;
}

.languageButtonDropdown:focus,
:global(.hover-styles-enabled).languageButtonDropdown:hover {
  background: colors.$gray05;
  color: colors.$gray95;
  outline: 0;
  path {
    fill: colors.$gray95;
  }
}

.languageButton, .languageButtonDropdown {
  @include typography.l-bold;
  display: block;
  width: 11rem;
  background: colors.$gray95;
  color: colors.$gray05;
  border: none;
  cursor: pointer;
  text-align: left;

  .languageSelectIcon {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.44rem 0.125rem 0;
    width: 0.875rem;
    height: 0.875rem;
  }
  .languageButtonLabel {
    display: inline-block;
    vertical-align: middle;
  }
  .languageSelectArrowDownIcon {
    display: inline-block;
    width: 0.875rem;
    height: 0.5625rem;
    margin-left: 0.555rem;
    vertical-align: middle;
  }
  .languageSelectedIcon {
    margin-left: 0.4583rem;
    width: 1.0625rem;
    height: 0.835rem;
    vertical-align: middle;
  }
}

.languageButton:focus,
:global(.hover-styles-enabled) .languageButton:hover {
  background: colors.$gray05;
  color: colors.$gray95;
  outline: 0;
  path {
    fill: colors.$gray95;
  }
}

.wrapper {
  height: 100%;
}

.content {
  width: 65.4%;
  margin: 5.71875rem auto 3.229rem auto;

  .header {
    margin: 0;
    padding: 0;
    text-align: left;
  }
}

.header {
  @include typography.two-xl-bold;
  margin: 0;
  padding: 3.75rem 0 2rem 0;
  text-align: center;
}

.body {
  @include typography.l-regular;
}

.link {
  @include typography.l-regular;
  font-weight: typography.$weight-bold;
  margin: 1rem 0 0 0;
}

.actions {
  text-align: center;
  margin: 0.583rem 0px;
}

.action:first-child {
  margin-right: 1.333rem;
}

.action {
  display: inline-block;
}

.editLink {
  @include typography.l-bold;
  display: inline-block;
  background: none;
  color: colors.$gray05;
  text-decoration: none;
  text-align: center;
  border-radius: 6.25rem;
  padding: 0.88rem 2.5rem;
}

.editLink:focus,
.editLink:hover,
:global(.hover-styles-enabled) .action button:hover {
  background: colors.$gray05;
  color: colors.$gray95;
  path {
    fill: colors.$gray95;
  }
}

.editContainer {
  width: 956px;
  margin: 0 auto;
}

.selectionContainer:not(:last-child) {
  margin-bottom: 2rem;
}

.selection {
  margin-bottom: 0.5rem;
}

.editConsent {
  .editColumn {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .checkboxLabel {
    @include typography.l-bold;
    line-height: 1.4;
    vertical-align: middle;
  }

  .editConsentBody {
    @include typography.l-regular;
    p {
      margin: 0;
    }
  }

  input[type='checkbox']:focus,
  input[type='checkbox']:hover {
    box-shadow:
      0px 0px 0px 0.125rem colors.$white,
      0px 0px 0px 0.25rem #121314;
  }

  input[type='checkbox'] {
    appearance: none;
    background-color: colors.$gray05;
    border: 0.125rem solid colors.$gray95;
    box-sizing: border-box;
    border-radius: 0.25rem;
    padding: 0.3rem 0.2rem 0.2rem;
    display: inline-block;
    position: relative;
    margin: 0 0.625rem 0 0;
    outline: 0;
    width: 1.333rem;
    height: 1.333rem;
    vertical-align: middle;
  }

  input[type='checkbox']:checked {
    background-color: colors.$turquoise60;
  }
  input[readonly]:checked {
    background-color: colors.$gray60;
  }

  input[type='checkbox']:checked:after {
    display: block;
    content: ' ';
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1.000000, 1.000000)' fill='%23F8F9FA'%3E%3Cpath d='M11.8047497,0.202772467 C12.0650834,0.473137833 12.0650834,0.911480214 11.8047497,1.18184766 L4.47141291,8.79723999 C4.21105921,9.06758667 3.78895196,9.06758667 3.52859827,8.79723999 L0.19526232,5.3357018 C-0.0650874401,5.06533436 -0.0650874401,4.62699197 0.19526232,4.35662453 C0.455612014,4.08626401 0.877721265,4.08626401 1.13807496,4.35662453 L4,7.32864486 L10.8619484,0.202772467 C11.1222821,-0.0675908223 11.544416,-0.0675908223 11.8047497,0.202772467 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
  }
}
