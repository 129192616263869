@use '../../styles/colors';

.popup {
  position: absolute;
  pointer-events: all;
  color: colors.$gray05;

  background-color: colors.$gray80;
  top: 0;
  right: 0;
  width: 32.375rem;
  height: 100vh;
  z-index: 100;
}

.content {
}

.popupHeading {
}

.sectionHeading {
}
